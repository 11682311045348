const isMobileHeaderName = 'x-is-mobile'

// "gb-ve" short for "gb-viewed-experiments", for obFusCaTiOn
const gbViewedExperimentsParamName = 'gb-ve'

const gbViewedExperimentsHeaderName = 'gb-viewed-experiments'
const gbActiveFeaturesHeaderName = 'gb-active-features'

module.exports = {
  gbViewedExperimentsParamName,
  gbViewedExperimentsHeaderName,
  gbActiveFeaturesHeaderName,
  isMobileHeaderName
}
