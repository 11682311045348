
import { mapGetters } from 'vuex'
import PaymentGatewayLogos from '~/components/payment-gateway-logos/PaymentGatewayLogos'
import TryggEhandel from '~/components/trygg-ehandel/TryggEhandel'
import TrustpilotWidget from '~/components/trustpilot/TrustpilotWidget.vue'
import WashingsWarranty from '~/components/washings-warranty/WashingsWarranty'
import FooterMenu from '~/components/footer/FooterMenu.vue'

export default {
  name: 'Footer',
  components: {
    FooterMenu,
    PaymentGatewayLogos,
    TryggEhandel,
    TrustpilotWidget,
    WashingsWarranty
  },
  data () {
    return {
      showing: false,
      viewportObserver: undefined,
    }
  },
  computed: {
    ...mapGetters({
      getMenuById: 'storyblok/getMenuById',
      countrySpecificInformation: 'storyblok/getCountrySpecificInformation',
      countryCode: 'frontend/currentCountryCode',
      getGbFeatureValue: 'growthbook/getGbFeatureValue',
      gbInitialized: 'growthbook/initialized',
    }),
    footerMenu () {
      return this.getMenuById('footer-menu')?.content
        ? this.getMenuById('footer-menu')?.content
        : { menu: [], trustLogos: [] }
    },
    menu () {
      return this.footerMenu?.menu[0]?.menus || []
    },
    trustpilotWidgetLanguageCode () {
      const dataLocale = this.countrySpecificInformation?.content?.TrustpilotWidget

      if (!dataLocale?.length) {
        return 'en-US'
      }

      return dataLocale[0].dataLocale
    },
    washingsWarranty () {
      return this.footerMenu?.washingsWarranty?.length
        ? this.footerMenu?.washingsWarranty[0]
        : null
    },
    svenskHandelIcon () {
      return this.countrySpecificInformation?.content?.svenskHandelIcon
    }
  },
  watch: {
    footerMenu: {
      deep: true,
      handler () {
        this.checkIntersection()
      }
    }
  },
  mounted () {
    if (this.footerMenu) {
      this.checkIntersection()
    }
  },
  unmounted () {
    this.viewportObserver?.disconnect()
  },
  methods: {
    // Check if image is in viewport
    checkIntersection () {
      if (this.showing || this.viewportObserver) {
        return
      }

      this.viewportObserver = new IntersectionObserver(
        this.handleIntersect,
        { rootMargin: '200px', threshold: 0.01 },
      )
      this.viewportObserver.observe(this.$el)
    },
    handleIntersect (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          this.showing = true
          this.viewportObserver?.unobserve(this.$el)
          this.viewportObserver?.disconnect()
        }
      })
    },
    convertToProxy (image) {
      return this.$imgproxy.transform(image, 'rs:fit:1000/g:nowe/q:70')
    }
  }
}
