import type { GetterTree, MutationTree, ActionTree } from 'vuex'
import dayjsDefault, { Dayjs } from 'dayjs'
import type { CentraOrderReceipt } from '@made-people/centra-models'
import { CrossSellInfo, RootState } from './types'
import { setupDayjs } from '~/plugins/dayjs'
import isAxiosError from '~/types/is-axios-error'

const dayjs = setupDayjs(dayjsDefault)

export const CROSS_SELL_STORAGE_ID = 'cross_sell'

export function state (): RootState['cross-sell'] {
  return {
    active: undefined,
  }
}

export const getters: GetterTree<RootState['cross-sell'], RootState> = {
  addedProducts (state) {
    return state.active?.addedProducts
  },
  initialOrderValue (state) {
    return state.active?.initialOrderValue
  },
  hasBeenAddedToOrder: state => (productId: string) => {
    return state.active?.addedProducts.includes(productId)
  },
  isActive (state) {
    return typeof state.active === 'object' && state.active !== null
  },
  getExpiration (state) {
    if (typeof state.active !== 'undefined') {
      let expirationDate: Dayjs | undefined
      if (state.active.expires) {
        expirationDate = state.active.expires
      }
      return expirationDate?.toString()
    }

    return undefined
  },
}

export const mutations: MutationTree<RootState['cross-sell']> = {
  registerProductAsAdded (state, productId: string) {
    if (!state.active?.addedProducts.includes(productId)) {
      state.active?.addedProducts.push(productId)
      localStorage.setItem(CROSS_SELL_STORAGE_ID, JSON.stringify(state.active))
    }
  },
  set (state, payload: CrossSellInfo) {
    if (
      typeof payload === 'object' &&
      payload !== null &&
      'orderId' in payload &&
      (typeof payload.orderId === 'number' ||
        typeof payload.orderId === 'string') &&
      'expiresString' in payload &&
      typeof payload.expiresString === 'string' &&
      'initialOrderValue' in payload &&
      typeof payload.initialOrderValue === 'number'
    ) {
      if (typeof payload.orderId === 'string') {
        payload.orderId = parseInt(payload.orderId)
      }
      if (isNaN(payload.orderId)) {
        return
      }

      state.active = payload

      localStorage.setItem(
        CROSS_SELL_STORAGE_ID,
        JSON.stringify(payload)
      )
    }
  },
  unset (state) {
    state.active = undefined
    localStorage.removeItem(CROSS_SELL_STORAGE_ID)
  },
}

export const actions: ActionTree<RootState['cross-sell'], RootState> = {
  unset ({ commit }) {
    commit('unset')
  },

  reviveFromStorage ({ commit }, orderId) {
    const stored = localStorage.getItem(CROSS_SELL_STORAGE_ID)
    if (stored) {
      try {
        const parsed = JSON.parse(stored)
        if (orderId !== parsed.orderId.toString()) {
          return
        }

        commit('set', parsed)
      } catch (e) {
        console.error('[cross-sell/reviveFromStorage]: Could not revive from storage', e)
      }
    }
  },

  setFromOrderReceipt ({ commit }, orderReceipt: CentraOrderReceipt) {
    if (
      typeof orderReceipt.order === 'string' &&
      typeof orderReceipt.date === 'string' &&
      typeof orderReceipt.totals.itemsTotalPriceAsNumber === 'number' &&
      // @ts-ignore
      typeof orderReceipt.crossSell === 'object' &&
      // @ts-ignore
      orderReceipt.crossSell !== null &&
      // @ts-ignore
      orderReceipt.crossSell.isAllowed === true &&
      // @ts-ignore
      typeof orderReceipt.crossSell.timeLimit === 'number' &&
      // @ts-ignore
      orderReceipt.crossSell.timeLimit > 0
    ) {
      try {
        const expirationDate = dayjs
          .tz(orderReceipt.date, 'Europe/Amsterdam')
          // @ts-ignore
          .add(orderReceipt.crossSell.timeLimit, 'minutes')
        const orderId = parseInt(orderReceipt.order)
        if (isNaN(orderId)) {
          throw new TypeError(
            '[cross-sell/setFromOrderReceipt]: Could not parse order number'
          )
        }

        if (expirationDate.isBefore(dayjs().utc())) {
          return
        }

        commit('set', {
          orderId,
          expires: expirationDate.utc(),
          expiresString: expirationDate.utc().toJSON(),
          initialOrderValue: orderReceipt.totals.itemsTotalPriceAsNumber,
          addedProducts: [],
        })
      } catch (e) {}
    }
  },
  async getCrossSalableProductsByIds (
    { state, getters },
    productIds: string[] | string
  ) {
    const result: Record<string, boolean> = {}
    if (typeof productIds === 'string') {
      productIds = [productIds]
    }

    if (
      !getters.isActive ||
      !Array.isArray(productIds) ||
      productIds.some(id => isNaN(parseInt(id))) ||
      productIds.length < 0
    ) {
      return undefined
    }

    try {
      const response = await this.$backendApi.get<{
        successful: string[]
        failed?: string[]
      }>(`/order/${state.active!.orderId}/cross-sell/${productIds.join(',')}`)
      for (const productId of response.data.successful) {
        result[productId] = true
      }
      for (const productId of response.data.failed ?? []) {
        result[productId] = false
      }
    } catch (e) {
      console.error(
        '[cross-sell/getCrossSalableProductsByIds]: Could not get cross-sellable products',
        e
      )
      return undefined
    }

    return result
  },
  async addToOrder ({ state, dispatch, commit }, productId: string) {
    try {
      const selection = (
        await this.$backendApi.post(
          `/order/${state.active!.orderId}/cross-sell/add/${productId}`
        )
      ).data
      dispatch('centra-cart/paymentResultByReceipt', selection.selection, {
        root: true,
      })
    } catch (err) {
      if (isAxiosError<{ error_code: string; message: string }>(err)) {
        if (err.response?.status === 403) {
          commit('unset')
        }
        return err.response?.data
      } else {
        console.error(
          `[cross-sell/addToOrder]: Could not add product with id ${productId} to order ${
            state.active!.orderId
          }`,
          err
        )
        return undefined
      }
    }
  },
}
