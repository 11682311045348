import storyblokStore from '@made-people/centra-storyblok-nuxt-shared/lib/store/storyblok'

const countryInformationFinder = it => it?.data?.content?.component === 'CountryInformation'

export default {
  ...storyblokStore,
  actions: {
    ...storyblokStore.actions,
    fetchStory ({ commit, rootState, getters }, { id, params }) {
      let lookupId = id
      const link = getters.getLinkForSlug(id)
      if (link) {
        lookupId = link.id
      }
      params = params || {}
      params.language = params.language || rootState.frontend.currentLanguageCode
      return this.$storyapi.get(`cdn/stories/${lookupId}`, { params }).then((response) => {
        commit('story', {
          id: lookupId,
          data: response.data.story
        })
        return response.data.story
      }).catch((e) => {
        // The .catch() is added
        console.error(`Couldn't fetch SB story with id ${id}:`, e?.response?.data)
      })
    },
  },
  getters: {
    ...storyblokStore.getters,
    getCountrySpecificInformation: state => (state.stories.find(countryInformationFinder) || { id: null, data: [] })?.data,
  }
}
