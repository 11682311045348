import { ContextError, CountryMissing } from '@made-people/centra-storyblok-nuxt-shared/lib/store/frontend'
import { mpGetFinalHostname } from '@made-people/express-middleware-base-url'
import { routeToLookupParts } from '~/store/frontend'
import { serverInitStore } from '~/lib/server-init-store'
import {
  gbActiveFeaturesHeaderName,
  gbViewedExperimentsHeaderName,
  gbViewedExperimentsParamName,
  isMobileHeaderName
} from '~/lib/fastly-constants'
import isAxiosError from '~/types/is-axios-error'

export default {
  actions: {
    /**
     * Set up all the country, market, language, pricelist things according to
     * the domain and url, and also fetch all globally shared CMS stuff
     */
    async nuxtServerInit ({ dispatch, commit, rootState, rootGetters }, { req, route, redirect, $config }) {
      let startTime
      try {
        startTime = new Date()
        const contexts = (await this.$backendApi.get('contexts')).data
        const lookupParts = routeToLookupParts({
          route,
          defaultLocale: $config.defaultLocale,
          defaultMarketSlug: $config.defaultMarketSlug,
          contexts,
          redirect
        })

        // Verify that the slug is a country that exists in centra
        let countryCode = contexts.countries.find(country => country.country === lookupParts.country.toUpperCase())?.country
        // market.name is used for matching and should be FQDN without any www. and so on.
        // If you want to test things locally you can add local.missmary.fr to your /etc/hosts
        const lookupMarket = contexts.markets.find((market) => {
          if (route.query._storyblok) {
            return market.countries.includes(lookupParts.currentPath)
          }
          return req.mpHostname.includes(market.name)
        }) || contexts.defaultMarket

        if (!countryCode) {
        // Make sure the market for the domain has this country
          if (req.countryCode && (lookupMarket.name === 'missmary.com' || lookupMarket.countries.includes(req.countryCode))) {
            countryCode = req.countryCode
          } else {
            countryCode = lookupMarket.defaultCountry
          }

          if (!countryCode) {
            countryCode = 'US'
          }

          const findCountry = countryCode => contexts.countries.find(country => country.country.toUpperCase() === countryCode.toUpperCase())
          let foundCountry = findCountry(countryCode)

          if (foundCountry === undefined) {
            countryCode = 'US'
            foundCountry = findCountry(countryCode)
          }

          // Build url and redirect to it
          let productUrl = ''
          let redirectSlug = ''
          const query = new URLSearchParams(
            (req.mpHostname.includes('missmary.com') ? `rc=${countryCode.toLowerCase()}&` : '') +
          (req?._parsedUrl?.search ?? '').replace('?', '')
          )
          let currencyCountries = []
          const articleId = parseInt(lookupParts.currentPath)
          if (route.query.fc) {
            currencyCountries = contexts.pricelists.find(pricelist => pricelist.name === route.query.fc.toUpperCase())?.countries
            if (currencyCountries && currencyCountries.length > 0) {
              if (currencyCountries.includes(countryCode)) {
                redirectSlug = countryCode.toLowerCase()
              } else {
                redirectSlug = route.query.fc === 'eur' ? 'ie' : currencyCountries[0].toLowerCase()
              }
            }
            delete route.query.fc
          }
          if (articleId > 99999 && articleId < 1000000) {
            try {
              productUrl = (await this.$backendApi.get('products/by-sku/' + lookupParts.currentPath)).data.url
            } catch (e) {
            }
          } else {
            productUrl = req.path.substring(1)
          }
          if (!redirectSlug) {
            redirectSlug = countryCode.toLowerCase()
          }

          const redirectTo = `/${redirectSlug}/${productUrl}?${query.toString()}`
          return redirect(redirectTo)
        }

        let marketToUse
        const countryInMarket = lookupMarket.countries.includes(countryCode)
        if (!countryInMarket) {
          const marketCountry = contexts.countries.find(country => country.country === countryCode)
          marketToUse = contexts.markets.find(market => market.market === marketCountry.market)
        } else {
          marketToUse = lookupMarket || contexts.defaultMarket
        }

        if (!marketToUse) {
          throw new ContextError(`No market matches the hostname ${req.mpHostname} or country code '${req.countryCode}'`)
        }

        const currentCountry = contexts.countries.find(country => country.country === countryCode.toUpperCase())
        if (!currentCountry) {
          throw new CountryMissing(`No shippable country exists in Centra for country code '${countryCode}'`)
        }

        const pricelist = contexts.pricelists.find(pricelist => pricelist.countries.includes(countryCode.toUpperCase()))
        if (!pricelist) {
          throw new ContextError(`No pricelist exists for country code '${countryCode}'`)
        }

        const marketsList = contexts.markets

        const geoIpCountryCode = req.countryCode

        const geoIpCountry = geoIpCountryCode
          ? contexts.countries.find(country => country.country.toLowerCase() === geoIpCountryCode.toLowerCase())
          : false

        // Miss mary doesn't have secondary languages
        let languageCode = currentCountry.language
        if (route.query._storyblok && route.query._storyblok_lang !== languageCode) {
          languageCode = route.query._storyblok_lang === 'default' ? lookupParts.defaultLocale : route.query._storyblok_lang
        }
        commit('frontend/currentLanguageCode', languageCode, { root: true })
        commit('frontend/currentCountryCode', countryCode, { root: true })
        commit('frontend/countries', contexts.countries, { root: true })
        commit('frontend/currentDomain', lookupMarket.name, { root: true })
        commit('frontend/market', marketToUse, { root: true })
        commit('frontend/marketsList', marketsList, { root: true })
        commit('frontend/geoIpCountry', geoIpCountry, { root: true })
        commit('frontend/pricelist', pricelist, { root: true })
        commit('frontend/pricelistAll', contexts.pricelists, { root: true })
        commit('frontend/mpHostname', req.mpHostname, { root: true })
        commit('ui/isMobile', req.headers[isMobileHeaderName] === '1', { root: true })

        // Set stuff from edge to vuex so we can do logic and track stuff
        await dispatch('growthbook/initialize', {
          activeFeaturesRaw: req.headers[gbActiveFeaturesHeaderName],
          viewedExperimentsRaw: req.query[gbViewedExperimentsParamName] || req.headers[gbViewedExperimentsHeaderName]
        })

        const gbRedirectFeature = await dispatch('growthbook/getRedirectTestURL')
        if (gbRedirectFeature) {
          const { key, value } = gbRedirectFeature

          // Generate the new url by replacing value.searchRegex with value.replaceWith
          const hostName = mpGetFinalHostname(req)
          const currentUrl = `${req.mpProto}://${hostName}${req.url}`
          const newUrl = currentUrl.replace(new RegExp(value.searchRegex), value.replaceWith)

          // Add viewed experiments to query string to be able to track the experiment in Growthbook
          // on the page we redirect to
          return redirect(302, newUrl, { [gbViewedExperimentsParamName]: req.headers[gbViewedExperimentsHeaderName] })
        }

        const currencyCode = pricelist.currency.currency
        const featureKey = `alternate-pricelist:${currencyCode.toLowerCase()}`
        if (rootGetters['growthbook/gbFeatureIsOn'](featureKey)) {
          try {
            const alternatePricelistId = rootGetters['growthbook/getGbFeatureValue'](featureKey, pricelist.pricelist)
            const alternatePricelist = contexts.pricelists.find(p => p.pricelist === `${alternatePricelistId}`)

            if (alternatePricelist) {
              if (
                alternatePricelist.currency.currency === pricelist.currency.currency
              ) {
                commit('frontend/pricelist', alternatePricelist, { root: true })
              }
            }
          } catch (err) {
            console.error('Error applying alternate pricelist. Falling back to default pricelist of country:\n', err)
          }
        }

        const languageFeatureKey = `alternate-language:${countryCode.toLowerCase()}`
        if (rootGetters['growthbook/gbFeatureIsOn'](languageFeatureKey)) {
          try {
            const alternateLanguageCode = rootGetters['growthbook/getGbFeatureValue'](languageFeatureKey, languageCode)
            commit('frontend/currentLanguageCode', alternateLanguageCode, { root: true })
          } catch (err) {
            console.error('Error applying alternate language. Falling back to default language of country:\n', err)
          }
        }

        dispatch('frontend/storeURLTrackingParameters', { route })

        try {
          await serverInitStore(
            {
              rootState,
              rootGetters,
              commit,
              dispatch
            },
            {
              $backendApi: this.$backendApi
            }
          )
        } catch (e) {
          console.error('Couldn\'t set up the store', e)
        }
      } catch (error) {
        const finishedTime = +new Date()
        const elapsedTime = finishedTime - startTime
        console.log(`nuxtServerInit time until exception: ${elapsedTime / 1000} seconds`)

        if (isAxiosError(error)) {
          const message = {
            errno: error.errno,
            code: error.code,
            request: {
              method: error.config.method,
              url: `${error.config.baseURL}/${error.config.url}`,
              params: error.config.params,
              headers: error.config.headers,
            },
          }
          if (error.response) {
            message.response = {
              data: JSON.stringify(error.response.data),
              status: error.response.status,
            }
          }
          console.error('AxiosError in nuxtServerInit', message)
        } else {
          console.error('Error in nuxtServerInit', error)
        }
      }
    }
  }
}
