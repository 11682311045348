/**
 * Google Analytics middleware that collects tracking information and stores in in
 * a store so that the google-analytics plugin can use that info client side.
 *
 * @param app Nuxt app
 */
function googleAnalyticsMiddleware ({ app }) {
  if (process.client) {
    // This middleware should not run client side because the code is written for an SSR 'app', not
    // a client side 'app'
    return
  }
  try {
    const query = app.context?.query ?? app.context?.req?.query ?? {}
    const headers = app.context?.req?.headers ?? {}
    const isLandingPage = Object.keys(query).find(key => key === 'gclid' || key.startsWith('utm_')) !== undefined
    let landingPagePath
    let url
    if (isLandingPage) {
      landingPagePath = app.context?.req?.originalUrl
      url = new URL(`${app.context.req?.mpGetBaseUrl(app.context.req)}${landingPagePath}`)
    }
    const trackingInfo = { url: url?.toString(), headers }
    app.store.dispatch('google-analytics/setTrackingInfo', trackingInfo)
  } catch (e) {
    console.error('google analytics middleware error', e)
  }
}

export default googleAnalyticsMiddleware
