import { Context, NuxtAppOptions } from '@nuxt/types'
import Vue from 'vue'
import { Store } from 'vuex'
import isAxiosError from '~/types/is-axios-error'

export default function logError (this: Vue | Store<any> | Context | NuxtAppOptions, error: unknown, prefix?: string) {
  const logException = (error: unknown) => {
    if (error instanceof Error) {
      error.message = (prefix ?? '') + error.message
    }
    if (this?.$sentry) {
      this?.$sentry.captureException(error)
    } else {
      console.error(error)
    }
  }
  const logError = (msg: string) => {
    if (this?.$sentry) {
      this?.$sentry.captureMessage((prefix ?? '') + msg, 'error')
    } else {
      console.error((prefix ?? ''), msg)
    }
  }

  if (isAxiosError(error)) {
    if (error.response !== undefined) {
      error.message = error.response.data
      if (typeof error.response.data === 'string') {
        error.message = error.response.data
      } else {
        try {
          error.message = JSON.stringify(error.response.data)
        } catch (e) {}
      }
    }
    logException(error)
    return
  } else if (error instanceof Error) {
    logException(error)
    return
  } else if (typeof error === 'object' && error !== null && 'message' in error && typeof error.message === 'string') {
    logException(new Error(error.message))
    return
  } else if (typeof error === 'string') {
    logError(error)
    return
  } else if (typeof error === 'object' && error !== null && 'toString' in error && typeof error.toString === 'function') {
    logError(error.toString())
    return
  }

  let jsonStringified = ''
  try {
    jsonStringified = JSON.stringify(error)
  } catch (e) {}
  if (typeof error === 'object' && error !== null && jsonStringified !== '') {
    logError(jsonStringified)
    return
  }

  console.warn('[log-error.ts]: Could not log a useful error')
}
