// @ts-nocheck
// @ts-ignore
import Routes from '@made-people/centra-storyblok-nuxt-shared/lib/store/routes'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from './types'

export function state (): RootState['routes'] {
  return {
    /**
     * An array of { route: 'something/foo/bar', component: 'storyblok' } that
     * can be used as a local lookup cache when finding what should be rendered
     *
     * The component key simply references a registered vue component
     */
    routes: [{
      route: '/route/search',
      component: {
        component: 'search',
        id: null,
        url: 'search',
        data: null
      }
    }],
    currentRoute: null
  }
}

export const getters: GetterTree<RootState['routes'], RootState> = {
  ...Routes.getters,
  currentPage (state, _getters, rootState, _rootGetters) {
    const pages = rootState.storyblok.stories
    return pages.find((it: any) => String(it.id) === String(state.currentRoute?.component.id))?.data?.content || {}
  },
}

export const mutations: MutationTree<RootState['routes']> = {
  ...Routes.mutations,
}

export const actions: ActionTree<RootState['routes'], RootState> = {
  ...Routes.actions,

  async lookupRoute ({ dispatch, commit, rootGetters, state, rootState }, routePath) {
    // The _storyblok code is spread all over the place until we
    // have a backend that can take care of it. So for now we mock
    // stuff
    if (this.$router.history.current.query._storyblok) {
      const story = await dispatch('storyblok/fetchStory', {
        id: this.$router.history.current.query._storyblok,
        language: this.$router.history.current.query._storyblok_lang
      }, { root: true })
      const data = {
        route: routePath,
        component: {
          component: 'storyblok',
          id: parseInt(this.$router.history.current.query._storyblok),
          url: routePath
        }
      }
      commit('addRoute', data)
      return Promise.resolve({
        ...data.component,
        data: story
      })
    }

    routePath = routePath.replace(/\/[\w-]+\/?/, '/')
    const route = '/route' + routePath

    // If we have the route in state already we don't need a new lookup
    const found = state.routes.find(x => x.route === route)
    if (found) {
      commit('addRoute', found)

      let data
      if (found.component.component === 'storyblok') {
        data = rootGetters['storyblok/getStoryById'](found.component.id)
      } else if (found.component.component === 'product') {
        data = rootGetters['centra-product/getProductById'](found.component.id)
      }
      return Promise.resolve({
        ...found.component,
        data
      })
    }

    return this.$backendApi
      .get(route, {
        params: {
          language: rootState.frontend.currentLanguageCode,
          country: rootState.frontend.currentCountryCode,
          pricelist: rootState.frontend.pricelist.pricelist,
          market: rootState.frontend.market.market
        }
      })
      .then((res) => {
        commit('addRoute', { route, component: { ...res.data, data: undefined } })
        if (res.data.component === 'storyblok') {
          commit('storyblok/story', {
            id: parseInt(res.data.id),
            data: res.data.data
          }, { root: true })
        } else if (res.data.component === 'product') {
          commit('centra-product/products', [res.data.data], { root: true })
        }
        return res.data
      })
      .catch((err) => {
        // If 404 it means we should not render anything here and but are fine
        // to resolve an undefined result up
        if (err.response && err.response.status === 404) {
          return undefined
        }

        // Handle when there's an exception that's not a 404
        let message = ''
        if (err instanceof Error) {
          message = [err.toString(), err.stack?.toString()].join('\n')
        } else {
          message = err
        }
        console.error('Route failed without 404', message)
      })
  },
}
