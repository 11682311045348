
import HamburgerIcon from '~/assets/icons/hamburger.svg'

export default {
  name: 'ToggleMenu',
  components: {
    HamburgerIcon
  },
  methods: {
    showMobileSidebar () {
      this.$root.$emit('globalResetMenuFunction')
      this.$store.dispatch('ui/showMobileSidebar')
    }
  }
}
