
export default {
  name: 'FooterMenu',
  props: {
    menu: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      // This value is set to the value emitted by the child
      childUid: -1
    }
  },
  methods: {
    // Triggered when 'clickToggle' event is emitted by the child
    openAccordion (value) {
      this.childUid = value
    },
    openPopup (event) {
      if (event === 'size-guide') {
        this.$store.dispatch('missmary-storyblok/fetchSizeGuide')
        this.$store.dispatch('ui/showSizeGuide')
      } else if (event === 'washing-instructions') {
        this.$store.dispatch('missmary-storyblok/fetchWashingInstructions')
        this.$store.dispatch('ui/showWashingInstructions')
      }
    }
  }
}
