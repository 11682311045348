
import { mapActions, mapGetters } from 'vuex'
import FilterAccordionItem from './FilterAccordionItem.vue'
import Nl2br from '~/mixins/nl2br.js'
import CrossIcon from '~/assets/icons/cross.svg'
import SelectedFilters from '~/components/product-filter/SelectedFilters.vue'

export default {
  name: 'FilterAccordionBlock',
  components: {
    FilterAccordionItem,
    CrossIcon,
    SelectedFilters,
  },
  mixins: [Nl2br],
  props: {
    filterData: {
      type: Object,
      required: false,
      default: () => ({})
    },
    accordionItems: {
      type: Array,
      required: true,
      default: () => ([])
    },
    requestId: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      groupId: this._uid
    }
  },
  computed: {
    ...mapGetters({
      generalSettings: 'storyblok/settings',
      totalProducts: 'algolia/totalProducts',
    }),
    sizeFacets () {
      const allFacets = this.filterData.finalFacets.filter(facet => facet.name.includes('size'))
      const cupFacets = allFacets.filter(facet => facet.name.includes('cup_type'))
      const facets = allFacets.filter(facet => !facet.name.includes('cup_type'))
      return { name: 'sizechart.sizechart_eu.type', values: { sizeFacets: facets?.[0], cupFacets: cupFacets?.[0] } }
    },
    cupFacets () {
      const cupShape = this.filterData.finalFacets.filter(facet => facet.name.startsWith('cup_shape'))
      const coverage = this.filterData.finalFacets.filter(facet => facet.name.startsWith('coverage'))
      const cupType = this.filterData.finalFacets.filter(facet => facet.name.startsWith('cup_type'))

      return { name: 'cup_info', values: { cupShape: cupShape?.[0], coverage: coverage?.[0], cupType: cupType?.[0] } }
    },
    sortingOrder () {
      const defaultSortingOrder = ['sizechart', 'wiring', 'color', 'bra_type', 'pantie_type', 'padding', 'features', 'cup_info', 'support', 'main_material', 'closure']
      return this.generalSettings?.content?.filterSortingOrder?.length
        ? this.generalSettings.content.filterSortingOrder
        : defaultSortingOrder
    },
    finalFacets () {
      const filteredFacets = this.filterData.finalFacets.filter(facet => !facet.name.includes('size') && !facet.name.startsWith('coverage') && !facet.name.startsWith('cup_type') && !facet.name.startsWith('cup_shape'))

      if (this.sizeFacets?.values?.sizeFacets?.values?.length > 0 || this.sizeFacets?.values?.cupFacets?.values?.length > 0) {
        filteredFacets.push(this.sizeFacets)
      }

      if (this.cupFacets?.values?.cupShape?.values?.length > 0 || this.cupFacets?.values?.coverage?.values?.length > 0 || this.cupFacets?.values?.cupType?.values?.length > 0) {
        filteredFacets.push(this.cupFacets)
      }

      const copyFilteredFacets = [...filteredFacets]
      const sortedFacets = []

      for (const order of this.sortingOrder) {
        const index = copyFilteredFacets.findIndex(facet => facet.name.startsWith(order))
        if (index !== -1) {
          const facet = copyFilteredFacets.splice(index, 1)[0]
          sortedFacets.push(facet)
        }
      }

      return [...sortedFacets, ...copyFilteredFacets]
    },
    totalProductResults () {
      return this.totalProducts(this.requestId)
    }
  },
  methods: {
    ...mapActions({
      hidefilterAccordionBlock: 'ui/hidefilterAccordionBlock',
      facetFilters: 'algolia/facetFilters',
    }),
    filterOptionStyle (item) {
      if (item.name.includes('size')) {
        return 'size'
      } else if (item.name === 'color') {
        return 'color'
      }
      return 'checkbox'
    }
  }
}
