import { getBannersId } from '~/store/missmary-storyblok'

/**
 * This method does all lookups from content/site and commits them
 * to the appropriate stores.
 *
 * Should be used inside nuxtServerInit
 */
export async function serverInitStore ({ rootState, rootGetters, commit, dispatch }, { $backendApi }) {
  const {
    footerMenu,
    mainMenu,
    settings,
    algoliaSettings,
    countrySpecificInformation,
    marketInformation,
    uiElementTranslations,
    links,
    yotpoSettings,
    banners,
    accountSettings,
    yotpoSiteReviewsBottomline
  } = (await $backendApi.get('content/site')).data

  if (uiElementTranslations) {
    commit('storyblok/elementTranslations', {
      locale: rootState.frontend.currentLanguageCode,
      entries: uiElementTranslations
    })
  }

  commit('storyblok/links', links)
  commit('yotpo/setSettings', yotpoSettings)
  commit('algolia/setSettings', algoliaSettings)

  if (footerMenu) {
    commit('storyblok/story', {
      id: footerMenu.id,
      data: footerMenu
    })
  }
  if (mainMenu) {
    commit('storyblok/story', {
      id: mainMenu.id,
      data: mainMenu
    })
  }
  if (settings) {
    commit('storyblok/story', {
      id: settings.id,
      data: settings
    })
  }
  if (countrySpecificInformation) {
    commit('storyblok/story', {
      id: countrySpecificInformation.id,
      data: countrySpecificInformation
    })
  }
  if (marketInformation) {
    commit('storyblok/story', {
      id: marketInformation.id,
      data: marketInformation
    })
  }
  if (banners) {
    commit('missmary-storyblok/story', {
      id: getBannersId(rootState.frontend.currentLanguageCode, rootGetters['frontend/currentMarketId']),
      data: banners
    })
  }
  if (accountSettings) {
    commit('account/setSettings', accountSettings)
  }
  if (yotpoSiteReviewsBottomline) {
    commit('yotpo/updateStoreReviewsBottomline', yotpoSiteReviewsBottomline)
  }
}
