
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentGatewayLogos',
  props: {
    isCartSidebar: {
      type: Boolean,
      required: false,
      default: false
    },
    desktopIconHeight: {
      type: String,
      required: false,
      default: null
    },
    mobileIconHeight: {
      type: String,
      required: false,
      default: null
    },
    isTopBanner: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      paymentGatewayLogos: []
    }
  },
  computed: {
    ...mapGetters({
      countrySpecificInformation: 'storyblok/getCountrySpecificInformation'
    }),
    importCssVariablesFromStoryblok () {
      if (this.isTopBanner) {
        return {
          '--mobile-icon-height': this.countrySpecificInformation?.content?.paymentLogosTopBannerMobileHeight || '20px',
          '--desktop-icon-height': this.countrySpecificInformation?.content?.paymentLogosTopBannerDesktopHeight || '20px',
        }
      }
      return {
        '--mobile-icon-height': this.mobileIconHeight || '20px',
        '--desktop-icon-height': this.desktopIconHeight || '20px',
      }
    },
  },
  created () {
    if (this.isTopBanner) {
      this.paymentGatewayLogos = this.countrySpecificInformation?.content?.PaymentLogosTopBanner || []
    } else {
      this.paymentGatewayLogos = this.countrySpecificInformation?.content?.PaymentGatewayLogos || []
    }
  },
  methods: {
    convertToProxy (image) {
      return this.$imgproxy.transform(image, 'rs:fit:1000/g:nowe/q:70')
    }
  }
}
