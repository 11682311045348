
import TickIcon from '~/assets/icons/tick.svg'
export default {
  name: 'AccordionFilterOption',
  components: {
    TickIcon
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    swatch: {
      type: String,
      default: ''
    },
    availableResults: {
      type: Number,
      default: 0
    },
    optionSelected: {
      type: Boolean,
      default: false
    },
    filterOptionStyle: {
      type: String,
      default: 'checkbox'
    }
  },
  computed: {
    imageProxyUrl () { return this.$config.imageProxyUrl },
    checkboxStyle () {
      if (this.swatch.trim() !== '') {
        return `background-image: url(${this.swatch})`
      }
      return ''
    },
    whiteSwatch () {
      return this.swatch?.includes('white')
    }
  },
  methods: {
    clickHandler () {
      this.$emit('click')
    }
  }
}
