
import Vue from 'vue'
import { mapMutations } from 'vuex'
import { CentraProduct } from '@made-people/centra-models'
import { Actions, addActionListener, removeActionListener } from '~/lib/actions'
import { ShowSizeSelectorParams } from '~/store/types'

export default Vue.extend<Data, Methods, {}, Props>({
  data () {
    return {
      mobile: false,
    }
  },
  mounted () {
    this.handleResize()
    addActionListener(Actions.ADD_TO_CART, this.addToCart)
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    removeActionListener(Actions.ADD_TO_CART)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapMutations({
      showSizeSelectorDesktop: 'ui/showSizeSelectorDesktop',
      showSizeSelectorMobile: 'ui/showSizeSelectorMobile',
      selectedProductSize: 'ui/selectedProductSize',
    }),
    handleResize () {
      if (typeof this.$refs.testIfMobile !== 'undefined') {
        this.mobile = window.innerWidth < 768
      }
    },
    // Add to cart action
    async addToCart (_sku: number) {
      try {
        const sku = _sku.toString()
        const product: CentraProduct = await this.$store.dispatch('centra-product/lookupProductBySku', sku)

        if (!product) {
          throw new Error('Product not found')
        }

        if (this.mobile) {
          this.showSizeSelectorMobile({ productID: product.product })
        } else {
          this.showSizeSelectorDesktop({ productID: product.product })
        }
      } catch (_err) {
        const err = _err as Error
        console.warn(`[custom_action_add_to_cart]: ${err.message}`)
      }
    }
  },
})

interface Data {
  mobile: boolean;
}

interface Methods {
  handleResize: () => void;
  selectedProductSize: (s: false | any) => void;
  showSizeSelectorMobile: (params: ShowSizeSelectorParams) => void;
  showSizeSelectorDesktop: (params: ShowSizeSelectorParams) => void;
  addToCart: (sku: number) => Promise<void>;
}

interface Props {
  product: CentraProduct;
  setInitialSizes: () => Promise<void>;
  getPostLoadCentraProductPromises: () => Promise<void>;
}
