
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      tehScript: undefined
    }
  },
  head () {
    if (this.TEH_ID) {
      return {
        script: [
          {
            src: `https://cert.tryggehandel.net/js/script.js?id=${this.TEH_ID}`,
            async: true,
            defer: true,
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      countrySpecificInformation: 'storyblok/getCountrySpecificInformation'
    }),
    TEH_ID () {
      return this.countrySpecificInformation?.content?.TEH_ID
    }
  }
}
