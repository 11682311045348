
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { isHidden } from '@/lib/utils'
import { ALGOLIA_KEY_SEARCH_RESULTS, ALGOLIA_KEYS } from '@/utils/algoliaListKeys'

import SearchIcon from '~/assets/icons/search.svg'
import CloseIcon from '~/assets/icons/cross.svg'

export default {
  name: 'SearchInput',
  components: {
    SearchIcon,
    CloseIcon
  },
  props: {
    searchOpen: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    searchQuery: '',
  }),
  computed: {
    ...mapGetters({
      searchQueryVuex: 'algolia/searchQuery',
      isInAlgoliaLists: 'algolia/isInLists',
    }),
    algoliaRequestId () {
      return ALGOLIA_KEY_SEARCH_RESULTS
    },
  },
  watch: {
    searchQuery (newSearchQuery, oldSearchQuery) {
      if (newSearchQuery !== oldSearchQuery) {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(async () => {
          await this.setSearchQuery({ id: this.algoliaRequestId, value: newSearchQuery })
          if (newSearchQuery.length > 0) {
            this.gtm_trackSearch(newSearchQuery)
          }
        }, 1000)
      }
    },
    async searchOpen (val) {
      let promise = Promise.resolve()
      if (!val) {
        this.searchQuery = ''
        promise = this.setSearchQuery({ id: this.algoliaRequestId, value: '' })
      }
      this.$nextTick(() => {
        if (val && !isHidden(this.$el)) {
          this.$refs.searchInput?.focus()
        } else {
          this.$refs.searchInput?.blur()
        }
      })
      await promise
    }
  },
  created () {
    for (const key of ALGOLIA_KEYS) {
      if (!this.isInAlgoliaLists(key)) {
        this.createAlgoliaList(key)
      }
    }
    this.syncSearchQueryWithVuex()
  },
  methods: {
    ...mapMutations({
      createAlgoliaList: 'algolia/createList'
    }),
    ...mapActions({
      setSearchQuery: 'algolia/setSearchQuery',
      hideSearch: 'ui/hideSearch',
    }),
    syncSearchQueryWithVuex () {
      this.searchQuery = this.isInAlgoliaLists(this.algoliaRequestId) ? this.searchQueryVuex(this.algoliaRequestId) : ''
    },
    getVariant (item) {
      return item.variants.find(it => it.id === item.selected_variant_id)
    },
    getUrlKey (item) {
      const variant = this.getVariant(item)
      return variant.custom_fields.url_key
    },
    getColor (item) {
      if (item.color && item.color[0]) {
        return item.color[0]
      }
      return null
    },
    getSize (item) {
      if (item.size && item.size[0]) {
        return item.size[0]
      }
      return null
    },
    getAction () {
      return this.$u('search')
    },
    searchSubmit () {
      this.gtm_trackSearch(this.searchQuery)
      this.hideSearch()
      this.$router.push({
        path: this.$u('search'),
        query: { q: this.searchQuery },
      })
      this.syncSearchQueryWithVuex()
    },
  },
}
