import Vue from 'vue'

Vue.mixin({
  methods: {
    /**
     * Hot replacement for vue-i18n method which happens to use translated elements
     * from storyblok
     */
    $t (name, format, rootGetters) {
      const getters = rootGetters || this.$store.getters
      format = format || {}
      const re = /{([^{]+)}/g
      const translation = getters['storyblok/getElementTranslation'](name)
      return translation.replace(re, (ignore, key) => {
        const term = format[key]
        return term === undefined ? `{${key}}` : term
      })
    },

    /**
     * If we always use this method we can actually
     * switch markets in SPA mode
     *
     * @param {string|Object} link
     */
    $u (link) {
      let slug
      if (typeof link === 'object') {
        // We guess it's an SB link and check if it's internal, external or whatever
        let linkObject
        switch (link.linktype) {
          case 'url':
            slug = link.url
            break
          case 'story':
            linkObject = this.$store.getters['storyblok/getLinkForUuid'](link.id)
            if (linkObject) {
              slug = linkObject.slug
            } else {
              // It's simply not set up in SB, so we cannot link it
              return null
            }
            break
          // Default means nothing so let's not do anythings
        }
      } else {
        slug = link
      }

      if (slug === null || slug === undefined || slug === false) {
        // Just prevent crashes if something is strangely passed
        return null
      }

      if (slug.match(/^https?:\/\//)) {
        return slug
      }

      // Check if anchorlink
      if (slug.match(/^#/)) {
        return slug
      }

      slug = slug.replace(/^site\/?/, '')
      const siteLangSlug = this.$store.getters['frontend/currentSiteLangSlug']
      return `/${siteLangSlug}/${slug}`
    },

    $openZopim () {
      const zopimIframe = document.getElementById('launcher')
      if (zopimIframe) {
        // eslint-disable-next-line no-unused-expressions
        zopimIframe.contentWindow.document
          .getElementsByClassName('u-userLauncherColor')[0]?.click()
      }
    },

    transformProduct (product) {
      return product ? {
        ...product,
        sizeOptions: product.sizeOptions.map((option) => {
          return {
            ...option,
            addToCart: () => {
              // Is this a cool pattern?
              return this.$store.dispatch('centra-cart/addItem', option.value).then((res) => {
                const item = res.items.find(item => item.line === res.line)
                if (this.gtm_trackAddToCart) {
                  this.gtm_trackAddToCart(item, 1, this.cart)
                }
                if (this.findifyData) {
                  this.$findifyAnalytics().sendEvent('add-to-cart', {
                    item_id: this.findifyData.itemGroupId,
                    variant_item_id: option.value,
                    quantity: 1,
                    rid: this.findifyData.rid
                  })
                  this.$findifyAnalytics().sendEvent('click-item', {
                    item_id: this.findifyData.itemGroupId,
                    variant_item_id: option.value,
                    rid: this.findifyData.rid
                  })
                }
              })// mock this?
            }
          }
        })
      } : null
    },

    $debounce (func, wait) {
      let timeout
      return function executedFunction (...args) {
        const later = () => {
          timeout = null
          func(...args)
        }
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
      }
    },

    $evaluateScriptsInRef (ref) {
      ;[].slice.call(ref.getElementsByTagName('script'))
        .filter(script => script.type === '' && script.src === '')
        // eslint-disable-next-line no-eval
        .forEach(script => eval(script.innerHTML))
    }
  }
})
