
import * as md5 from 'md5'
export default {
  name: 'ProductListController',
  props: {
    query: {
      type: [Object, String],
      required: true
    },
    limitProducts: {
      type: String,
      default: '15'
    },
    offsetProducts: {
      type: String,
      default: '0'
    },
    productsFrom: {
      type: String,
      default: ''
    },
    centraProducts: {
      type: [Object, String],
      default: null
    },
    centraCategory: {
      type: String,
      default: '',
    },
    centraProductIds: {
      type: String,
      default: '',
    },
    algoliaCentraCategory: {
      type: String,
      default: null // so fucking stupid
    },
    algoliaMerchandisingRule: {
      type: String,
      default: null // so fucking stupid
    },
    algoliaRequestId: {
      type: String,
      default: null // so fucking stupid
    },
    isEmptySearch: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      resultsKey: 'results'
    }
  },
  computed: {
    algoliaSearchQuery () {
      return this.query?.q || ''
    },
    onFrontPage () {
      // Render findify client side on front page to save TTFB
      return this.$store.state.routes?.currentRoute?.component?.url === ''
    },

    blokQuery () {
      return this.query
    },

    queryName () {
      return md5(JSON.stringify(this.blokQuery))
    },

    centraProductSkus () {
      return this.centraProducts?.tbody?.map(it => it.body[0].value).filter(it => !!it)
    },
    centraSearchQuery () {
      // support both URI query for category and pin product ID:s
      if (this.centraCategory?.length) {
        return {
          uri: {
            uri: this.centraCategory,
            for: ['category'],
          },
        }
      } else if (typeof this.centraProductIds === 'string' && this.centraProductIds?.length) {
        return {
          products: this.centraProductIds.split(','),
        }
      }

      return undefined
    },

    /**
     * Use this to set up any type of query from the storyblok interface that
     * findify can understand
     *
     */
    initialQuery () {
      const final = {
        q: this.blokQuery.q,
        filters: [],
        rules: []
      }

      if (this.limitProducts > 0) {
        final.limit = parseInt(this.limitProducts)
      }

      return final
    },

    /**
     * We need to separate pagination from the starting point
     */
    initialOffset () {
      return this.offsetProducts ? parseInt(this.offsetProducts) : 0
    },

    rules () {
      return [
        {
          query: {
            rules: this.blokQuery.rules,
            pins: this.blokQuery.pins
          }
        }
      ]
    },
  },
  watch: {
    blokQuery () {
      this.resultsKey = 'results-' + (new Date()).getTime()
    },
  },
}
