export const getSizechartStandard = (code) => {
  return mappings[code] || sizechartStandardFallback
}

export const getAllCupTypeFilters = () => {
  return sizechartStandards.map(std => `sizechart_${std}.cup_type`)
}

export const getAllTypeFilters = () => {
  return sizechartStandards.map(std => `sizechart_${std}.type`)
}

export const getAllValueFilters = () => {
  return sizechartStandards.map(std => `sizechart_${std}.value`)
}

export const getAllLabelFilters = () => {
  return sizechartStandards.map(std => `sizechart_${std}.label`)
}

export const getAllFilters = () => {
  return [].concat(
    getAllCupTypeFilters(),
    getAllTypeFilters(),
    getAllValueFilters(),
    getAllLabelFilters()
  )
}

export const sizechartStandardFallback = 'eu'

export const sizechartStandards = [
  'france',
  'us',
  'italy',
  'uk',
  'aus',
  'eu'
]

export const mappings = {
  us: 'us',
  ca: 'us',
  fr: 'france',
  pt: 'france',
  es: 'france',
  it: 'italy',
  cz: 'italy',
  gb: 'uk',
  ie: 'uk',
  au: 'aus',
  nz: 'aus',
  se: 'eu',
  no: 'eu',
  dk: 'eu',
  fi: 'eu',
  de: 'eu',
  nl: 'eu',
  pl: 'eu'
}

export function formatFacetFilterToQuery (facetName, value) {
  switch (getWhitelistedFiltersCategoryFromFacetName(facetName)) {
    case 'categories.uri':
    case 'coverage':
    case 'cup_shape':
    case 'cup_type':
    case 'main_material':
    case 'padding':
    case 'support':
    case 'wiring':
    case 'closure': {
      if (!value) { return undefined }
      const modifiedFacetName = facetName.replace('.', '__')
      return { facetName: modifiedFacetName, value: value.replace(' ', '---') }
    }
    case 'color': {
      let color = value.split('||').shift() ?? value
      if (!color) { return undefined }
      color = color.replaceAll(' ', '---')
      return { facetName, value: color }
    }
    case 'size_type': {
      return { facetName: 'size_type', value }
    }
    case 'size_cup_type': {
      return { facetName: 'size_cup_type', value }
    }
    default: {
      return undefined
    }
  }
}

export function formatFacetFilterFromQuery ({ facetName: _facetName, value: _value, sizechartStandard, facets }) {
  const values = Array.isArray(_value) ? _value : [_value]
  if (!values) { return undefined }
  const facetName = _facetName.replace('__', '.')
  const formattedValues = []
  for (const value of values) {
    switch (getWhitelistedFiltersCategoryFromUriSafe(facetName)) {
      case 'category':
      case 'coverage':
      case 'cup_shape':
      case 'cup_type':
      case 'main_material':
      case 'padding':
      case 'support':
      case 'wiring':
      case 'closure': {
        if (!value) {
          break
        }
        formattedValues.push({ facetName, value: value.replace('---', ' ') })
        break
      }
      case 'color': {
        if (!facets) {
          console.warn('`facets` is undefined in formatFacetFilterFromQuery. Need to be passed to get color name')
          break
        }
        if (!facets.color) {
          break
        }
        if (!value) {
          break
        }
        const color = Object.keys(facets.color).find(f => f.startsWith(value.replace('---', ' ')))
        if (!color) { break }
        formattedValues.push({ facetName, value: color })
        break
      }
      case 'size_type': {
        if (!sizechartStandard) {
          console.warn('`sizechartStandard` is undefined in formatFacetFilterFromQuery. Need to be passed to get size type name in sizechart')
          break
        }
        formattedValues.push({ facetName: `sizechart.sizechart_${sizechartStandard}.type`, value })
        break
      }
      case 'size_cup_type': {
        if (!sizechartStandard) {
          console.warn('`sizechartStandard` is undefined in formatFacetFilterFromQuery. Need to be passed to get size type name in sizechart')
          break
        }
        formattedValues.push({ facetName: `sizechart.sizechart_${sizechartStandard}.cup_type`, value })
        break
      }
      default: {
        break
      }
    }
  }
  if (!formattedValues.length) { return undefined }
  return formattedValues
}

/**
 * Whitelisted facet filters that can be used in the URI
 * @readonly
 */
export const whitelistedUriFacetFilters = {
  // category: [{ facetName: 'categories.uri', uriSafe: 'category' }],
  coverage: [{ facetName: 'coverage', uriSafe: 'coverage' }],
  cup_shape: [{ facetName: 'cup_shape', uriSafe: 'cup_shape' }],
  cup_type: [{ facetName: 'cup_type', uriSafe: 'cup_type' }],
  main_material: [{ facetName: 'main_material', uriSafe: 'main_material' }],
  padding: [{ facetName: 'padding', uriSafe: 'padding' }],
  support: [{ facetName: 'support', uriSafe: 'support' }],
  wiring: [{ facetName: 'wiring', uriSafe: 'wiring' }],
  closure: [{ facetName: 'closure', uriSafe: 'closure' }],
  color: [{ facetName: 'color', uriSafe: 'color' }],
  size_type: [
    { facetName: 'sizechart.sizechart_aus.type', uriSafe: 'size_type' },
    { facetName: 'sizechart.sizechart_eu.type', uriSafe: 'size_type' },
    { facetName: 'sizechart.sizechart_italy.type', uriSafe: 'size_type' },
    { facetName: 'sizechart.sizechart_france.type', uriSafe: 'size_type' },
    { facetName: 'sizechart.sizechart_uk.type', uriSafe: 'size_type' },
    { facetName: 'sizechart.sizechart_us.type', uriSafe: 'size_type' },
  ],
  size_cup_type: [
    { facetName: 'sizechart.sizechart_aus.cup_type', uriSafe: 'size_cup_type' },
    { facetName: 'sizechart.sizechart_eu.cup_type', uriSafe: 'size_cup_type' },
    { facetName: 'sizechart.sizechart_france.cup_type', uriSafe: 'size_cup_type' },
    { facetName: 'sizechart.sizechart_italy.cup_type', uriSafe: 'size_cup_type' },
    { facetName: 'sizechart.sizechart_uk.cup_type', uriSafe: 'size_cup_type' },
    { facetName: 'sizechart.sizechart_us.cup_type', uriSafe: 'size_cup_type' },
  ],
}

/**
 * @type {() => string[]}
 */
export function getWhitelistedFilterFacetNames () {
  const whitelistedFilterFacetNames = []
  for (const arr of Object.values(whitelistedUriFacetFilters)) {
    for (const { facetName } of arr) {
      whitelistedFilterFacetNames.push(facetName)
    }
  }
  return whitelistedFilterFacetNames
}

/**
 * @type {(searchedFacetName: string) => string}
 */
function getWhitelistedFiltersCategoryFromFacetName (searchedFacetName) {
  let whitelistedFiltersCategoryFromFacetName = ''
  for (const [category, arr] of Object.entries(whitelistedUriFacetFilters)) {
    for (const { facetName } of arr) {
      if (facetName === searchedFacetName) {
        whitelistedFiltersCategoryFromFacetName = category
        break
      }
    }
  }
  return whitelistedFiltersCategoryFromFacetName
}

/**
 * @type {(searchedUriSafe: string) => string}
 */
function getWhitelistedFiltersCategoryFromUriSafe (searchedUriSafe) {
  let whitelistedFiltersCategoryFromUriSafe = ''
  for (const [category, arr] of Object.entries(whitelistedUriFacetFilters)) {
    for (const { uriSafe } of arr) {
      if (uriSafe === searchedUriSafe) {
        whitelistedFiltersCategoryFromUriSafe = category
        break
      }
    }
  }
  return whitelistedFiltersCategoryFromUriSafe
}
