import { mapGetters } from 'vuex'

/**
 * Inits widgets on mounted + supplies script tag to be inserted into head()
 * Insert into head from mixin apparently gets overridden by component head() function
 */
export default {
  computed: {
    ...mapGetters({
      yotpoSettings: 'yotpo/settings'
    }),
    yotpoHeadScript () {
      return this.yotpoSettings?.loyaltyEnabled
        ? {
            type: 'text/javascript',
            src: `https://cdn-widgetsrepository.yotpo.com/v1/loader/${this.yotpoSettings.loyaltyGUID}`,
            async: true,
          }
        : {}
    }
  },
  methods: {
    initYotpoWidgets () {
      window?.yotpoWidgetsContainer?.initWidgets && window?.yotpoWidgetsContainer?.initWidgets()
    },
    initYotpoTestimonials () {
      const e = document.createElement('script')
      e.type = 'text/javascript'
      e.async = !0
      e.src = `//staticw2.yotpo.com/${this.yotpoSettings.reviewsAppKey}/widget.js`
      const t = document.getElementsByTagName('script')[0]
      t.parentNode.insertBefore(e, t)
    }
  },
}
