
import ChevronIcon from '~/assets/icons/chevron.svg'

export default {
  name: 'Button',
  components: {
    ChevronIcon
  },
  props: {
    label: {
      type: String,
      required: true
    },
    buttonStyle: {
      type: String,
      required: false,
      default: 'solid'
    },
    buttonColor: {
      type: String,
      required: false,
      default: '#383838'
    },
    textColor: {
      type: String,
      required: false,
      default: '#FFFFFF'
    },
    link: {
      type: Object,
      required: true
    },
    targetBlank: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: Boolean,
      required: false,
      default: false
    },
    position: {
      type: String,
      required: false,
      default: ''
    },
    margin: {
      type: String,
      required: false,
      default: '--none'
    }
  },
  computed: {
    colorsCss () {
      return {
        '--background-color': this.buttonColor,
        '--text-color': this.textColor
      }
    }
  }
}
