

export default {
  name: 'ImageBlock',
  props: {
    mobileImage: {
      type: [Object, String],
      required: false,
      default: null
    },
    desktopImage: {
      type: [Object, String],
      required: false,
      default: null
    },
    mobileImageWidth: {
      type: String,
      required: false,
      default: null
    },
    desktopImageWidth: {
      type: String,
      required: false,
      default: null
    },
    mobileImageAspectRatio: {
      type: String,
      required: false,
      default: null
    },
    desktopImageAspectRatio: {
      type: String,
      required: false,
      default: null
    },
    mobileHorizontallyCenter: {
      type: Boolean,
      required: false,
      default: null
    },
    desktopHorizontallyCenter: {
      type: Boolean,
      required: false,
      default: null
    },
    link: {
      type: [Object, String],
      required: false,
      default: null
    },
    backgroundColor: {
      type: String,
      required: false,
      default: ''
    },
    desktopIndentation: {
      type: String,
      required: false,
      default: ''
    },
    padding: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Image display sizes for optimized images
     * Can be sent from a parent, ie the grid component
     * Defaults to 100vw if not sent
     */
    mobileDisplayWidthOfViewportWidth: {
      type: Number,
      required: false,
      default: 100
    },
    desktopDisplayWidthOfViewportWidth: {
      type: Number,
      required: false,
      default: 100
    },
    lazyload: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Intersection root margin for intersection observer when lazyloading image
     * Defaults to 150px
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#root_margin
     */
    intersectionRootMargin: {
      type: Number,
      default () {
        return 150
      },
    },
  },
  computed: {
    backgroundColorCss () {
      return this.backgroundColor ? { backgroundColor: this.backgroundColor } : ''
    },
    importCssVariablesFromStoryblok () {
      return {
        '--mobile-image-width': this.mobileImageWidth,
        '--desktop-image-width': this.desktopImageWidth,
        '--mobile-image-aspect-ratio': this.mobileImageAspectRatio,
        '--desktop-image-aspect-ratio': this.desktopImageAspectRatio,
      }
    }
  }
}
