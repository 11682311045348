import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/sv'
import dayjsDefault from 'dayjs'
import { Context as NuxtContext } from '@nuxt/types'

export function setupDayjs (dayjs: typeof dayjsDefault) {
  dayjs.extend(weekOfYear)
  dayjs.extend(isoWeek)
  dayjs.extend(isoWeeksInYear)
  dayjs.extend(isLeapYear)
  dayjs.extend(utc)
  dayjs.extend(timezone)
  return dayjs
}

export default ({ app: { $dayjs } }: NuxtContext) => {
  setupDayjs($dayjs)
}
