export enum CartWorkerEventTypes {
  Connected = 0,
  CartRequestParams,
  CartRequestContext,
  CartResponse,
}

export interface CartRequestParams {
  requestUrl: string
  headers: Record<string, string>
}
export function isCartRequestParams (x: any): x is CartRequestParams {
  return typeof x === 'object' && x !== null && typeof x.requestUrl === 'string' && typeof x.headers === 'object' && x.headers !== null
}

export interface CartRequestContext {
  market: string;
  language: string;
  country: string;
}
export function isCartRequestContext (x: any): x is CartRequestContext {
  return typeof x === 'object' && x !== null && (typeof x.market === 'string' || typeof x.language === 'string' || typeof x.country === 'string')
}
