import Vue from 'vue'

const yotpoBase = 'https://api.yotpo.com'
const reviewVotesLocalStorageKey = 'yotpo/reviews'

export const sortingMethods = {
  relevance: {
    text: 'Reviews_Sort_Relevance',
    yotpoName: 'votes_up',
    direction: 'desc',
  },
  newest: {
    text: 'Reviews_Sort_Newest',
    yotpoName: 'date',
    direction: 'desc',
  },
  highest: {
    text: 'Reviews_Sort_Highest',
    yotpoName: 'rating',
    direction: 'desc',
  },
  lowest: {
    text: 'Reviews_Sort_Lowest',
    yotpoName: 'rating',
    direction: 'asc',
  },
}

const getLocalVotes = () => JSON.parse(window.localStorage.getItem(reviewVotesLocalStorageKey) || '{}')
const getLocalVote = reviewId => getLocalVotes()[reviewId]

const setLocalVotes = localVotes => window.localStorage.setItem(reviewVotesLocalStorageKey, JSON.stringify(localVotes))
const setLocalVote = (reviewId, upOrDown) => {
  const votes = getLocalVotes()
  votes[reviewId] = upOrDown
  setLocalVotes(votes)
}

const removeLocalVote = (reviewId) => {
  setLocalVote(reviewId, undefined)
}

export default {
  state () {
    return {
      token: null,
      settings: null,
      loadingSettings: true,

      /* REVIEWS */
      allReviews: {},
      productReviews: {},
      trustpilotProductReviewsNextPage: {},
      yotpoProductReviewsNextPage: {},
      productReviewsHasNextPage: {},
      loadingReviews: false,
      reviewsSorting: 'newest',
      reviewsVotes: {},
      storeReviews: null,
      storeReviewsBottomline: null,

      /* QUESTIONS */
      productQuestions: {},
      productQuestionsNextPage: {},
      productQuestionsHasNextPage: {},
      loadingQuestions: false,
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    clearToken (state) {
      state.token = null
    },
    setSettings (state, settings) {
      state.settings = settings
      state.loadingSettings = false
    },

    /* REVIEWS */
    updateProductReviews (state, payload) {
      const { sku, product, tPage, resetProductReviews, yPage } = payload
      const reviews = product === null ? [] : product.reviews
      const fromStore = state.productReviews[sku]?.reviews ?? []

      if (fromStore.length !== 0 && !resetProductReviews) {
        Vue.set(state.productReviews[sku], 'reviews', fromStore.concat(reviews))
      } else {
        Vue.set(state.productReviews, sku, product)
      }

      if (product !== null) {
        Vue.set(state.productReviewsHasNextPage, sku, product.reviews.length !== 0)
        if (product.pagination?.hasImportedReviews) {
          Vue.set(state.yotpoProductReviewsNextPage, sku, yPage + 1)
        }
        Vue.set(state.trustpilotProductReviewsNextPage, sku, tPage + 1)
      }
    },
    updateStoreReviews (state, payload) {
      if (payload) {
        state.storeReviews = payload
      }
    },
    updateStoreReviewsBottomline (state, payload) {
      if (payload) {
        state.storeReviewsBottomline = payload
      }
    },
    setLoadingReviews (state, payload) {
      state.loadingReviews = payload
    },
    resetPagination (state, payload) {
      state.productQuestionsHasNextPage[payload] = undefined
      state.trustpilotProductReviewsNextPage[payload] = undefined
      state.yotpoProductReviewsNextPage[payload] = undefined
    },
    setReviewsSortingMethod (state, payload) {
      state.reviewsSorting = payload
    },
    syncVotesWithLocalStorage (state) {
      state.reviewsVotes = getLocalVotes()
    },
    changeReviewVotes (state, payload) {
      const { productSku, reviewId, upOrDown, remove } = payload
      const productReviewsCopy = structuredClone(state.productReviews[productSku])
      const review = productReviewsCopy.reviews.find(review => review.id === reviewId)
      const delta = remove ? -1 : +1

      review[`votes_${upOrDown}`] = review[`votes_${upOrDown}`] + delta
      Vue.set(state.productReviews, productSku, productReviewsCopy)
    },

    /* QUESTIONS */
    updateProductQuestions (state, payload) {
      const { sku, product, page } = payload
      const questions = product === null ? [] : product.questions
      const fromStore = state.productQuestions[sku]?.questions ?? []

      if (fromStore.length !== 0) {
        Vue.set(state.productQuestions[sku], 'questions', fromStore.concat(questions))
      } else {
        Vue.set(state.productQuestions, sku, product)
      }

      if (product !== null) {
        // Since yotpo doesn't send pagination data for questions, we have to guess
        const numberOfPages = Math.ceil(product.total_questions / product.per_page)
        Vue.set(state.productQuestionsHasNextPage, sku, page < numberOfPages)
        Vue.set(state.productQuestionsNextPage, sku, page + 1)
      }
    },
    setLoadingQuestions (state, payload) {
      state.loadingQuestions = payload
    }
  },
  actions: {
    /* REVIEWS */
    // Get all reviews
    loadProductReviews ({ commit, state }, { sku, reset }) {
      reset = reset ?? false

      if ((!(state.productReviewsHasNextPage[sku] ?? true) && !reset)) {
        return
      }

      const tPage = state.trustpilotProductReviewsNextPage[sku] ?? 1
      const yPage = state.yotpoProductReviewsNextPage[sku] ?? 1
      const sorting = sortingMethods[state.reviewsSorting]

      commit('setLoadingReviews', true)
      const updateConfig = { sku, tPage, resetProductReviews: reset, yPage }
      // const feedUrl = `${yotpoBase}/v1/widget/${reviewsAppKey}/products/${sku}/reviews.json?page=${page}&sort=${sorting.yotpoName}&direction=${sorting.direction}`
      return this.$backendApi.get(`trustpilot/product-reviews/${sku}?t_page=${tPage}&y_page=${yPage}`)
        .then((response) => {
          commit('updateProductReviews', { product: response.data.response, ...updateConfig })
        })
        .catch(() => {
          commit('updateProductReviews', { product: null, ...updateConfig })
        })
        .finally(() => {
          commit('setLoadingReviews', false)
        })
    },
    loadStoreReviews ({ commit }) {
      commit('setLoadingReviews', true)
      return this.$backendApi.get('yotpo/site-reviews')
        .then((response) => {
          commit('updateStoreReviews', response.data)
        })
        .catch(() => {
          console.error('[yotpo/loadStoreReviews]: Could not fetch yotpo store reviews from backend')
          commit('updateStoreReviews', null)
        })
        .finally(() => {
          commit('setLoadingReviews', false)
        })
    },
    postProductReview (_, { rating, title, text, name, email }) {
      alert('Implement me')
    },
    setReviewsSortingMethod ({ commit, state, dispatch, rootState }, method) {
      const newSorting = state.reviewsSorting !== method
      commit('setReviewsSortingMethod', method)

      if (newSorting) {
        const { productSku: sku } = rootState.ui.product

        commit('resetPagination', sku)
        dispatch('loadProductReviews', { sku, reset: true })
      }
    },
    voteOnReview ({ commit, rootState }, { upOrDown, reviewId }) {
      const url = `https://api.yotpo.com/reviews/${reviewId}/vote`
      const localVote = getLocalVote(reviewId)
      const { productSku } = rootState.ui.product

      const doVote = (upOrDown, remove = false) => {
        let _url = url + `/${upOrDown}`

        if (remove) {
          // This indicates that we want to remove the vote (ok yotpo)
          _url += '/true'
        }

        remove ? removeLocalVote(reviewId) : setLocalVote(reviewId, upOrDown)
        commit('syncVotesWithLocalStorage')
        commit('changeReviewVotes', { productSku, reviewId, upOrDown, remove })
        return fetch(_url, { method: 'POST' })
      }

      if (localVote === undefined) {
        return doVote(upOrDown)
      }

      if (localVote === upOrDown) {
        return doVote(upOrDown, true)
      } else {
        const inverse = upOrDown === 'up' ? 'down' : 'up'
        return Promise.all([
          doVote(inverse, true),
          doVote(upOrDown),
        ])
      }
    },
    syncVotesWithLocalStorage ({ commit }) {
      commit('syncVotesWithLocalStorage')
    },

    /* QUESTIONS */
    loadProductQuestions ({ commit, state }, sku) {
      if (!state.settings || (!(state.productQuestionsHasNextPage[sku] ?? true))) {
        return
      }

      const page = state.productQuestionsNextPage[sku] ?? 1
      const { reviewsAppKey } = state.settings
      if (!reviewsAppKey) {
        console.warn('[yotpo/loadProductQuestions]: No reviews app key found')
        return
      }

      commit('setLoadingQuestions', true)
      const updateConfig = { sku, page }
      const feedUrl = `https://mm-yotpo-questions.storage.googleapis.com/${reviewsAppKey}/${sku}.json`
      return fetch(feedUrl)
        .then(response => response.json())
        .then((json) => {
          commit('updateProductQuestions', { product: json.response, ...updateConfig })
        })
        .catch(() => {
          commit('updateProductQuestions', { product: null, ...updateConfig })
        })
        .finally(() => {
          commit('setLoadingQuestions', false)
        })
    },
    postProductQuestion ({ rootState, state }, { text, name, email }) {
      const { productSku: sku } = rootState.ui.product
      const data = {
        display_name: name,
        review_content: text,
        email,
        sku,
      }

      return this.$backendApi.post('/yotpo/question', data)
    },
  },
  getters: {
    getProductReviews: state => (sku) => {
      if (state.productReviews[sku]) {
        return state.productReviews[sku]
      }
      return null
    },
    getStoreReviewsBottomline: (state) => {
      if (state.storeReviewsBottomline) {
        return state.storeReviewsBottomline
      }
      return null
    },
    getStoreReviews: (state) => {
      if (state.storeReviews) {
        return state.storeReviews
      }
      return null
    },
    getProductHasMoreReviews: state => (sku) => {
      return state.productReviewsHasNextPage[sku] ?? true
    },
    getProductQuestions: state => (sku) => {
      if (state.productQuestions[sku]) {
        return state.productQuestions[sku]
      }
      return null
    },
    getProductHasMoreQuestions: state => (sku) => {
      return state.productQuestionsHasNextPage[sku] ?? true
    },
    settings: state => state.settings,
    loadingSettings: state => state.loadingSettings,
    getOwnReviewVote: state => reviewId => state.reviewsVotes[reviewId]
  }
}
