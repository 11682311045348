
import InfoIcon from '~/assets/icons/info.svg'
import CrossIcon from '~/assets/icons/cross.svg'

export default {
  name: 'FeatureNotice',
  components: {
    InfoIcon,
    CrossIcon
  },
  methods: {
    closeNotice () {
      this.$emit('show-notice', false)
    }
  }
}
