
import { mapActions, mapGetters } from 'vuex'
import CrossIcon from '~/assets/icons/cross.svg'

export default {
  components: {
    CrossIcon,
  },
  props: {
    requestId: {
      type: String,
      required: true,
    },
    inFilterDesign: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
      facetFilters: 'algolia/facetFilters',
    }),
    selectedFilters () {
      const requestFacetFilters = this.facetFilters(this.requestId)
      return requestFacetFilters.filter(x => x.facetName !== 'categories.uri')
    },
    cssVars () {
      return {
        '--horizontal-padding-mobile': this.inFilterDesign ? '2.4rem' : '0',
        '--horizontal-padding-desktop': this.inFilterDesign ? '4rem' : '0',
        '--vertical-padding': this.inFilterDesign ? '2rem' : '0'
      }
    },
  },
  methods: {
    ...mapActions({
      toggleFacet: 'algolia/toggleFacet',
      resetFilters: 'algolia/resetFilters',
    }),
    getFilteredLabel (value) {
      return value.includes('||') ? value.split('||')[0] : value
    },
  },
}
