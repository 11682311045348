
import Nl2br from '~/mixins/nl2br.js'
import ChevronIcon from '~/assets/icons/chevron.svg'
import MinusIcon from '~/assets/icons/minus.svg'

export default {
  name: 'FooterMenuRow',
  components: {
    ChevronIcon,
    MinusIcon
  },
  mixins: [Nl2br],
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: Array,
      required: false,
      default: () => {}
    },
    id: {
      type: Number,
      required: true
    },
    open: {
      type: Number,
      required: true
    },
    textColor: {
      type: String,
      required: false,
      default: '#000000'
    }
  },
  data () {
    return {
      initialized: false
    }
  },
  computed: {
    textColorCss () {
      return { '--text-color': this.textColor }
    },
    sanatiseText () {
      return this.text.replace('/site', '')
    }
  },
  mounted () {
    this.setHeight()
  },
  methods: {
    setHeight () {
      /**
       * Before hiding each section we need to get the initial height and set it
       * as a data attribute to be used later. We do this so that we can animate
       * to a fixed height.
       */
      const element = this.$refs.expand
      if (!element.hasAttribute('data-height')) {
        element.style.display = 'block'
        // Total number of child elements multiplied by height of each row
        element.dataset.height = this.content.length * 38 + 'px'
        element.style.height = 0
        this.initialized = true
      }
    },
    getHeight () {
      /**
       * When the section is opened, we use the value from the data-height attribute
       * to set the height of the section.
       */
      if (this.initialized) {
        if (this.open === this.id) {
          return { height: this.$refs.expand.dataset.height }
        } else {
          return { height: '0px' }
        }
      } else {
        return false
      }
    },
    toggle () {
      /**
       * Instead of simply toggling this component, the value is emitted to the parent
       * and then passed back down. By doing this all siblings will be closed when this
       * accordion is opened.
       */
      if (this.open === this.id) {
        this.$emit('clickToggle', -1)
      } else {
        this.$emit('clickToggle', this.id)
      }
    },
    openPopup (event) {
      if (event === 'size-guide') {
        this.$store.dispatch('missmary-storyblok/fetchSizeGuide')
        this.$store.dispatch('ui/showSizeGuide')
      } else if (event === 'washing-instructions') {
        this.$store.dispatch('missmary-storyblok/fetchWashingInstructions')
        this.$store.dispatch('ui/showWashingInstructions')
      }
    }
  }
}
