

import { mapGetters } from 'vuex'
import SearchEmpty from '~/components/search/SearchEmpty.vue'

export default {
  name: 'Error',
  components: {
    SearchEmpty
  },
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      link: {
        url: '',
        cached_url: '/'
      },
      showSearch: true
    }
  },
  head () {
    return {
      title: this.generalSettings?.errorMetaTitle + ' 404' || this.$t('Page_Not_Found') + ' 404',
      bodyAttrs: {
        class: 'error'
      }
    }
  },
  computed: {
    ...mapGetters({
      currentLanguageCode: 'frontend/currentLanguageCode',
      currentCountryCode: 'frontend/currentCountryCode',
      generalSettings: 'storyblok/settings'
    })
  },
  mounted () {
    this.gtm_trackPage('404')
  }
}
