import type { CentraOrderReceipt, CentraProduct, CentraSelection } from '@made-people/centra-models'
import { Rule, SearchResponse } from '@algolia/client-search'
import type { Route } from 'vue-router'
import { WidenPrimitives } from '@growthbook/growthbook'
import { optional, object, string, Output, literal, union, merge, record, any, safeParse } from 'valibot'
import { Dayjs } from 'dayjs'
import { AlgoliaProduct } from '@/types/algolia'

export interface CrossSellState {
  active: CrossSellInfo | undefined
}

export interface CrossSellInfo {
  orderId: number
  expires?: Dayjs
  expiresString: string
  addedProducts: string[]
  initialOrderValue: number
}

export interface RootState {
  ['cross-sell']: CrossSellState;
  checkout: CheckoutState;
  ['centra-cart']: CentraCartState;
  ui: UiState;
  algolia: AlgoliaState;
  routes: RoutesState;
  route: Route;
  storyblok: StoryblokState;
  ['centra-product']: CentraProductState;
  growthbook: GrowthBookState;
  frontend: FrontendState
}

export interface FrontendState {
  whitelistedQueryParams: Record<string, string>
}

export interface CentraProductState {
  products: CentraProduct[];
}

export interface StoryblokState {
  stories: any;
}

export interface CheckoutState {
  isLoading: boolean;
  isLoadingPaypalButton: boolean;
  isLoadingStripeButton: boolean;
  address: CheckoutAddressField;
  shippingAddress: CheckoutAddressField;
  errors: any;
  shipToBillingAddress: boolean;
  klarnaCheckoutSnippet?: string,
  stripeData?: FormActionStripe,
  stripeError: boolean,
  paypalData?: FormActionPaypal,
  paypalError: boolean,
}

export interface CentraCartState {
  selection: CentraSelection;
  payment: any;
  shipping: any;
  order: any;
  receipt: CentraOrderReceipt;
}

export interface AlgoliaState {
  settings: any;
  filterOpen: boolean;
  selectedTab: AlgoliaProductFilterTab;
  enableDiscountFilter: boolean;
  ruleConditions: Record<string, Rule['conditions']>;
  canonicalFacetFilters?: string;
  lists: {
    [id: string]: {
      consumerWasDestroyed: boolean;
      currentConsumer: number | false;
      searchQuery: string;
      offset: number;
      limit: number;
      pageSize: number;
      initialFacetFilters: FacetFilter[];
      facetFilters: FacetFilter[];
      initialFacets: SearchResponse<AlgoliaProduct>['facets'];
      facets?: SearchResponse<AlgoliaProduct>['facets'];
      algoliaMerchandisingRule?: string;
      productsFrom?: 'from-algolia-rule';
      products?: SearchResponse<AlgoliaProduct>['hits'];
      totalProducts: SearchResponse<AlgoliaProduct>['nbHits'];
      queryID?: SearchResponse<AlgoliaProduct>['queryID'];
      listenToQueryParams: boolean;
      // A string with JSON describing how to get the current product lists
      oldQuery?: string;
    }
  }
}

export interface RoutesState {
  routes: RouteInfo[];
  currentRoute: RouteInfo;
}

export interface UiState {
  mobileSidebarOpen: boolean;
  desktopSubmenuOpen: boolean;
  miniCartOpen: boolean;
  searchOpen: boolean;
  searchAutocompleteResult: null;
  mobileProductFilterOpen: boolean;
  enableDiscountFilter: boolean;
  currentQueryName: null;
  selectedProductID: string;
  isProductDiscontinued: true | '';
  productItems: Array<unknown> | null;
  filterAccordionBlockData: null;
  filterAccordionBlockOpen: boolean;
  sizeSelectorMobileOpen: boolean;
  sizeSelectorDesktopOpen: boolean;
  customisationPopupOpen: boolean;
  productRestockSidebarOpen: boolean;
  addedProduct: string;
  addedPrimarySize: string;
  addedSecondarySize: string;
  washingInstructions: boolean;
  sizeGuide: boolean;
  productType: null;
  questionsSidebarOpen: boolean;
  selectedProductSize: false | any;
  productReviewsSidebarOpen: boolean;
  productQuestionsSidebarOpen: boolean;
  selectedProductSizeMatching: Record<string, unknown>;
  cartPopupOpenMobile: boolean;
  cartPopupOpenDesktop: boolean;
  countrySelectorPopup: boolean;
  addToCartLoadingMatchingProducts: null;
  addToCartLoadingMatchingSwimwearProducts: null;
  lastClickedQueryID: null;
  currentProduct: CentraProduct | null;
  renderCrossSell: boolean;
  // Product is used to load product specific reviews
  product: null | Partial<CentraProduct>;
  // Selected item used for restock
  itemId: null;
  /**
   * Overlay
   * Hidden if set to 0
   * The header has a z-index of 10, so set this to either
   * above or below 10 depending on use case
   */
  overlayIndex: number;
  freeShippingEnabled: boolean;
  algoliaProductFilter: {
    open: boolean;
    tab: AlgoliaProductFilterTab;
  };
  isMobile: boolean;
}

export type GrowthBookFeatureValue = WidenPrimitives<string | number | boolean | Record<string, any> | any[]>

interface GrowthBookState {
  hasLoadedGbFeatures: boolean;
  gbFeatures: Record<string, GrowthBookFeatureValue>;
  gbViewedExperiments: Array<{
    experimentId: string;
    variationId: string;
  }>;
  initializedOnClient: boolean;
  initialized: boolean;
  attributes: GrowthbookAttributes;
}

// =============================== Other ===============================

export interface GrowthbookAttributes {
  id?: string;
  country?: string;
  market?: string;
  url?: string;
  language?: string;
}

export interface InitializeOptions {
  trackingCallback?: (experimentId: string, variationId: number) => void;
  activeFeaturesRaw?: string;
  viewedExperimentsRaw?: string;
}

export type AlgoliaProductFilterTab = 'category' | 'size' | 'color' | undefined;

export interface RouteInfo {
  component: {
    component: string;
    data: any;
    id: number;
    url: string;
  };
  route: string;
}

export interface FacetFilter {
  facetName: string;
  value: any;
}

export interface CheckoutAddressField {
  email?: string;
  firstName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  state?: string;
  phoneNumber?: string;
}

export interface ShowSizeSelectorParams {
  productID: string;
  findifyData?: any;
  discontinued?: true;
  productItems?: any[];
}

const formActionSchema = object({
  action: literal('form'),
  formHtml: string(),
  formType: string(),
})
export type FormAction = Output<typeof formActionSchema>

export const formActionStripeSchema = merge([formActionSchema, object({
  formFields: object({
    externalScript: string(),
    publishableKey: string(),
    stripeParameters: string(),
  })
})])
export type FormActionStripe = Output<typeof formActionStripeSchema>
export function isFormActionStripe (x: any): x is FormActionStripe {
  return safeParse(formActionStripeSchema, x).success
}

export const formActionPaypalSchema = merge([formActionSchema, object({
  formFields: object({
    id: string(),
  })
})])
export type FormActionPaypal = Output<typeof formActionPaypalSchema>
export function isFormActionPaypal (x: any): x is FormActionPaypal {
  return safeParse(formActionPaypalSchema, x).success
}

export const stripeFinilizeResponseSchema = object({
  action: string(),
  url: string(),
})
export type StripeFinilizeResponse = Output<typeof stripeFinilizeResponseSchema>
export function isStripeFinilizeResponse (x: any): x is StripeFinilizeResponse {
  return safeParse(stripeFinilizeResponseSchema, x).success
}

export const paypalFinilizeResponseSchema = object({
  selectionId: string(),
  res: optional(object({
    action: string()
  }))
})
export type PaypalFinilizeResponse = Output<typeof paypalFinilizeResponseSchema>
export function isPaypalFinilizeResponse (x: any): x is PaypalFinilizeResponse {
  return safeParse(paypalFinilizeResponseSchema, x).success
}

export const centraErrorResponseSchema = object({
  errors: record(string(), any())
})
export type CentraErrorResponse = Output<typeof centraErrorResponseSchema>
export function isCentraErrorResponse (x: any): x is CentraErrorResponse {
  return safeParse(centraErrorResponseSchema, x).success
}

export const errorResponseSchema = union([object({
  errors: record(string(), any())
}), object({ unknownError: string() })])
export type ErrorResponse = Output<typeof errorResponseSchema>
export function isErrorResponse (x: any): x is ErrorResponse {
  return safeParse(errorResponseSchema, x).success
}
