import { wishlistLocalStorageKey } from '@made-people/centra-storyblok-nuxt-shared/lib/store/wishlist'

export default ({ store }) => {
  if (process.client) {
    store.dispatch('wishlist/initialize')
    store.subscribe((mutation, state) => {
      if (mutation.type.startsWith('wishlist')) {
        localStorage.setItem(wishlistLocalStorageKey, JSON.stringify(state.wishlist.productIDs))
      }
    })
  }
}
