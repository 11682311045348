import Vue from 'vue'

import * as md5 from 'md5'
import { mapGetters } from 'vuex'

Vue.mixin({
  computed: {
    ...mapGetters({
      market: 'frontend/market',
      pricelist: 'frontend/pricelist',
      currentLanguageCode: 'frontend/currentLanguageCode',
      currentCountryCode: 'frontend/currentCountryCode'
    })
  },
  mounted () {
    // Lazy init of the dataLayer that the GTM initialize will use
    window.dataLayer = window.dataLayer || []

    // Don't hate me for using window here
    window.queuedProductImpressions = window.queuedProductImpressions || []
  },
  methods: {
    gtm_trackPage (pageType, pageName) {
      pageName = pageName || pageType

      const payload = {
        event: 'pageView',
        pageType,
        pageName
      }

      if (this.$store.state?.account?.account?.isLoggedIn) {
        // How to make this work on initial page load since this fires before
        // the account xhr has finished loading?
        payload.userId = this.$store.state.account.account.customer
      }

      payload.language = this.currentLanguageCode
      payload.country = this.currentCountryCode

      window.dataLayer.push(payload)
    },

    gtm_trackProductClick (product, list) {
      const productId = product.id
      const sku = product.custom_fields?.centra_sku?.[0]
      if (!sku) {
        console.error(`gtm_trackProductClick: Product id=${productId} sku not found`)
        return
      }
      const name = product.title?.trim()
      if (!name) {
        console.error(`gtm_trackProductClick: Product id=${productId} name not found`)
        return
      }
      const currencyCode = this.pricelist?.currency?.uri
      if (!currencyCode) {
        console.error(`gtm_trackProductClick: Product id=${productId} currency not found`)
        return
      }
      const priceString = product.custom_fields?.[`price_${currencyCode}`]?.[0]
      if (priceString === undefined) {
        console.error(`gtm_trackProductClick: Product id='${productId}' price not found for currency '${currencyCode}'`)
        return
      }
      let price
      try {
        price = parseFloat(priceString)
      } catch (e) {
        console.error(`gtm_trackProductClick: Product id='${productId}' failed to parseFloat price string for currency '${currencyCode}`, e)
        return
      }
      const category = product.custom_fields.product_type?.[0]?.trim()
      if (!category) {
        console.error(`gtm_trackProductClick: Product id='${productId}' category not found`)
        return
      }
      const payload = {
        event: 'productClick',
        ecommerce: {
          currencyCode,
          click: {
            actionField: {
              list,
            },
            products: [{
              id: sku,
              name,
              price,
              category,
            }]
          }
        }
      }
      console.debug('gtm_trackProductClick', payload)
      window.dataLayer.push(payload)
      this.$store.dispatch('gtm/lastPushedClick', payload)
    },

    async gtm_trackProductDetail (product) {
      const payload = {
        event: 'productDetail',
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          detail: {
            products: [
              {
                name: product.name,
                id: product.sku,
                brand: product.brandName,
                category: product.product_type,
                price: parseFloat(product.priceAsNumber),
                dimension7: product.inStock,
                dimension8: product.variantName,
                dimension9: Object.values(product.items).map(i => i.sizeLabel)
              }
            ]
          }
        }
      }

      if (this.$store.state.gtm) {
        const pushedClick = await this.$store.dispatch('gtm/popLastPushedClick')
        if (pushedClick) {
          const list = pushedClick?.ecommerce?.click?.actionField?.list
          if (list) {
            payload.ecommerce.detail.actionField = { list }
          }
        }
      }

      window.dataLayer.push(payload)
    },

    gtm_trackProductImpression (impressions) {
      window.dataLayer.push({
        event: 'productImpressions',
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          impressions
        }
      })
    },

    /**
     * We queue impressions to 20 and flush them either when number 20 is
     * pushed or after the queue has existed for 0.5 seconds. The reason for
     * this is that you can push too much data by mistake into GTM and we
     * don't want to do that, while we also want to push the separate product
     * as they appear in the browser window
     */
    gtm_queueProductImpression (product, list, position) {
      try {
        window.queuedProductImpressions.push({
          id: product.custom_fields.centra_sku[0],
          name: product.title,
          price: parseFloat(product.price[0]),
          category: product.custom_fields.product_type?.[0],
          list,
          position
        })
        const flushImpressionQueue = () => {
          this.gtm_trackProductImpression(window.queuedProductImpressions)
          window.queuedProductImpressions = []
        }
        clearTimeout(window.impressionPushTimer)
        if (window.queuedProductImpressions.length >= 20) {
          flushImpressionQueue()
        } else {
          window.impressionPushTimer = setTimeout(flushImpressionQueue, 500)
        }
      } catch (e) {
        // Failing quietly
        //console.error('gtm_queueProductImpression failed')
      }
    },

    gtm_trackAddToCart (item, quantity) {
      window.dataLayer.push({
        event: 'addToCart',
        eventLabel: item.productName,
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          add: {
            products: [
              {
                name: item.productName,
                id: item._product.sku,
                price: parseFloat(item.priceEachAsNumber),
                category: item._product.product_type,
                brand: item.brandName,
                variant: item.item,
                quantity: quantity || item.quantity,
                dimension7: true,
                dimension8: item._product.variantName,
                dimension9: item.sizeLabel
              }
            ]
          }
        }
      })
    },

    gtm_trackRemoveFromCart (item, quantity) {
      window.dataLayer.push({
        event: 'removeFromCart',
        eventLabel: item.productName,
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          remove: {
            products: [
              {
                name: item.productName,
                id: item._product.sku,
                price: parseFloat(item.priceEachAsNumber),
                category: item._product.product_type,
                brand: item.brandName,
                variant: item.item,
                quantity
              }
            ]
          }
        }
      })
    },

    gtm_trackCheckoutStep (cart, step) {
      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          checkout: {
            actionField: { step },
            products: cart.items.map(item => ({
              name: item.productName,
              id: item._product.sku,
              price: parseFloat(item.priceEachAsNumber),
              category: item._product.product_type,
              brand: item.brandName,
              variant: item.size, // keeping this so history doesn't fail
              dimension7: true,
              dimension8: item._product.variantName,
              dimension9: item.sizeLabel,
              quantity: item.quantity
            }))
          }
        }
      })
    },

    gtm_trackPurchase (order) {
      let coupon = ''
      if (order?.discounts?.vouchers) {
        coupon = Object.values(order.discounts.vouchers)
          .map(voucher => `${voucher.description} (${voucher.voucher})`)
          .join(', ')
      }
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          currencyCode: order.currency,
          purchase: {
            actionField: {
              hashedEmail: md5(order.address.email),
              id: order.order, // Transaction ID. Required for purchases and refunds.
              affiliation: this.market.name,
              revenue: parseFloat(order.totals.grandTotalPriceAsNumber), // Total transaction value (incl. tax and shipping)
              tax: parseFloat(order.totals.grandTotalPriceTaxAsNumber),
              shipping: parseFloat(order.totals.shippingPriceAsNumber),
              revenueSubtotal: parseFloat((order.totals.grandTotalPriceAsNumber - order.totals.grandTotalPriceTaxAsNumber - order.totals.shippingPriceAsNumber)),
              coupon
            },
            products: order.items.map(item => ({
              id: item._product.sku,
              name: item._product.name,
              sku: item.sku,
              price: parseFloat(item.priceEachAsNumber),
              variant: item.size,
              brand: item.brandName,
              quantity: item.quantity,
              category: item._product.product_type,
              dimension7: true,
              dimension8: item._product.variantName,
              dimension9: item.sizeLabel
            }))
          }
        }
      })
    }
  }
})
