import { render, staticRenderFns } from "./FilterAccordionBlock.vue?vue&type=template&id=4e963f9d&scoped=true"
import script from "./FilterAccordionBlock.vue?vue&type=script&lang=js"
export * from "./FilterAccordionBlock.vue?vue&type=script&lang=js"
import style0 from "./FilterAccordionBlock.vue?vue&type=style&index=0&id=4e963f9d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e963f9d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/button/Button.vue').default,SelectedFilters: require('/usr/src/app/components/product-filter/SelectedFilters.vue').default,FilterAccordionItem: require('/usr/src/app/components/accordion-block/FilterAccordionItem.vue').default})
