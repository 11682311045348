import { Module } from 'vuex'
import { JSONValue, WidenPrimitives } from '@growthbook/growthbook'
import { GrowthBookFeatureValue, InitializeOptions, RootState } from './types'

const growthbookStoreModule: Module<RootState['growthbook'], RootState> = {
  state () {
    return {
      hasLoadedGbFeatures: false,
      gbFeatures: {},
      gbViewedExperiments: [],
      initializedOnClient: false,
      initialized: false,
      attributes: {},
    }
  },
  getters: {
    gbFeatures (state) {
      return state.gbFeatures || {}
    },
    gbViewedExperiments (state) {
      return state.gbViewedExperiments || []
    },
    gbFeatureKeys (_, getters) {
      return Object.keys(getters.gbFeatures)
    },
    getGbFeatureValue: state => <T extends JSONValue>(key: string, defaultValue: T) => {
      if (typeof state.gbFeatures?.[key] !== 'undefined') {
        return state.gbFeatures?.[key] as WidenPrimitives<T>
      }
      return defaultValue
    },
    gbFeatureIsOn: state => (key: string) => {
      return typeof state.gbFeatures?.[key] !== 'undefined'
    },
    initialized: state => state.initialized,
  },
  mutations: {
    gbFeatures (state, gbFeatures: RootState['growthbook']['gbFeatures']) {
      state.gbFeatures = gbFeatures
    },
    gbViewedExperiments (state, gbViewedExperiments: RootState['growthbook']['gbViewedExperiments']) {
      state.gbViewedExperiments = gbViewedExperiments
    },
    initializedOnClient (state, initializedOnClient: RootState['growthbook']['initializedOnClient']) {
      state.initializedOnClient = initializedOnClient
    },
    initialized (state, initialized: RootState['growthbook']['initialized']) {
      state.initialized = initialized
    },
    hasLoadedGbFeatures (state, hasLoadedGbFeatures: RootState['growthbook']['hasLoadedGbFeatures']) {
      state.hasLoadedGbFeatures = hasLoadedGbFeatures
    },
    attributes (state, attributes: RootState['growthbook']['attributes']) {
      state.attributes = { ...state.attributes, ...attributes }
    },
  },
  actions: {
    // Needs to be executed in both backend and frontend
    initialize ({ commit, state }, { activeFeaturesRaw, viewedExperimentsRaw }: InitializeOptions = {}) {
      if (state.initializedOnClient || !this.$config.growthbook?.clientKey) {
        return
      }

      if (process.server) {
        if (activeFeaturesRaw) {
          try {
            const activeFeatures: { featureId: string, value: GrowthBookFeatureValue }[] = JSON.parse(Buffer.from(activeFeaturesRaw, 'base64').toString('ascii'))
            commit(
              'gbFeatures',
              activeFeatures.reduce<RootState['growthbook']['gbFeatures']>((acc, { featureId, value }) => {
                acc[featureId] = value
                return acc
              }, {})
            )
          } catch (err) {
            console.error('[growthbook/initialize]: Could not set Growthbook features from header.', err)
          }
        }
        if (viewedExperimentsRaw) {
          try {
            const viewedExperiments: {
              experimentId: string;
              variationId: string;
            }[] = JSON.parse(Buffer.from(viewedExperimentsRaw, 'base64').toString('ascii'))
            commit('gbViewedExperiments', viewedExperiments)
          } catch (err) {
            console.error('[growthbook/initialize]: Could not set Growthbook viewed experiments from header.', err)
          }
        }
      }
    },
    getRedirectTestURL ({ getters }) {
      const redirectFeatures = (getters.gbFeatureKeys as string[])
        .filter(
          (key): key is `redirect-test:${string}` =>
            typeof key === 'string' && key.startsWith('redirect-test:')
        )
        .map((key) => {
          const value: string | undefined = getters.getGbFeatureValue(
            key,
            undefined
          )
          if (!isRedirectFeatureValue(value)) {
            return undefined
          }
          return [key, value] as [
            string,
            string,
          ]
        })
        .filter((entry): entry is [string, string] => typeof entry !== 'undefined')

      if (redirectFeatures.length === 0) {
        return undefined
      }

      if (redirectFeatures.length > 1) {
        console.warn('Received multiple redirect tests from Growthbook. Picking the first one.')
      }

      return { key: redirectFeatures[0][0], value: redirectFeatures[0][1] }
    }
  }
}

export type RedirectFeatureValue = { searchRegex: string, replaceWith: string };

function isRedirectFeatureValue (
  value: any
): value is RedirectFeatureValue {
  return (
    typeof value === 'object' &&
    value !== null &&
    'searchRegex' in value &&
    'replaceWith' in value &&
    typeof value.searchRegex === 'string' &&
    typeof value.replaceWith === 'string'
  )
}

export default growthbookStoreModule
