
import { mapActions, mapGetters } from 'vuex'
import Nl2br from '~/mixins/nl2br.js'
import ChevronIcon from '~/assets/icons/chevron.svg'
import AccordionFilterOption from '~/components/product-filter/AccordionFilterOption.vue'

export default {
  name: 'FilterAccordionItem',
  components: {
    ChevronIcon,
    AccordionFilterOption
  },
  mixins: [Nl2br],
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    id: {
      type: Number,
      required: true,
      default: null
    },
    groupId: {
      type: Number,
      required: true,
      default: null
    },
    requestId: {
      type: String,
      required: true,
    },
    filterOptionStyle: {
      type: String,
      required: false,
      default: 'radio'
    },
  },
  data () {
    return {
      isActive: false,
    }
  },
  computed: {
    ...mapGetters({
      facetFilters: 'algolia/facetFilters',
      facetsVuex: 'algolia/facets',
    }),
    requestFacetFilters () {
      return this.facetFilters(this.requestId)
    },
    algoliaFacets () {
      return this.facetsVuex(this.requestId)
    },
    numberOfSelectedFilters () {
      return this.requestFacetFilters.filter(rff => rff.facetName === this.item?.item?.name)?.length
    },
    cssVars () {
      return {
        '--padding-bottom-toggle-item': this.isActive ? '0' : '3.2rem',
        '--padding-bottom-content': this.isActive ? '3.2rem' : '0',
      }
    }
  },
  methods: {
    ...mapActions({
      toggleFacet: 'algolia/toggleFacet',
    }),
    toggle () {
      this.isActive = !this.isActive
    },
    startTransition (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    endTransition (el) {
      el.style.height = ''
    },
    isFacetValueSelected (facetName, value) {
      return !!this.requestFacetFilters.find(rff => rff.facetName === facetName && rff.value === value)
    },
    isFacetValueActive (facetName, value) {
      return !!this.algoliaFacets?.[facetName]?.[value]
    },
    getFacetOptionCount (facet, option) {
      return this.algoliaFacets[facet.name]?.[option.value]
    },
    getSwatchImage (value) {
      return value?.split('||')[1]
    },
    getLabel (value) {
      return value?.includes('||') ? value?.split('||')[0] : value
    },
  },

}
