
import { mapGetters } from 'vuex'
import CartIcon from '~/assets/icons/cart.svg'

export default {
  name: 'ToggleCart',
  components: { CartIcon },
  props: {
    device: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      cartQty: 'centra-cart/cartQty',
    }),
  },
  watch: {
    cartQty () {
      const cartIcon = this.$refs.carticon
      if (cartIcon) {
        cartIcon.classList.add('pop')
        setTimeout(() => {
          cartIcon.classList.remove('pop')
        }, 2000)
      }
    }
  },
  methods: {
    toggleMiniCart () {
      if (this.device === 'desktop') {
        this.$store.dispatch('ui/toggleMiniCart')
      } else {
        this.$router.push(this.$u('checkout'))
      }
    }
  }
}
