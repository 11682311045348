import { render, staticRenderFns } from "./CountrySelectorIcon.vue?vue&type=template&id=29d79a24&scoped=true"
import script from "./CountrySelectorIcon.vue?vue&type=script&lang=js"
export * from "./CountrySelectorIcon.vue?vue&type=script&lang=js"
import style0 from "./CountrySelectorIcon.vue?vue&type=style&index=0&id=29d79a24&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29d79a24",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlagIcon: require('/usr/src/app/components/country-selector/FlagIcon.vue').default})
