export default {
  state: () => ({
    account: null,
    settings: {}
  }),
  getters: {
    isLoggedIn (state) {
      return state.account && !!state.account.isLoggedIn
    }
  },
  mutations: {
    setAccount (state, account) {
      state.account = account
    },
    setSettings (state, settings) {
      state.settings = settings
    }
  },
  actions: {
    async getAccount ({ commit }) {
      if (process.server) {
        throw new Error('[account/getAccount]: This has to be called from the client side')
      }

      try {
        const response = await this.$backendApi.get('account')
        if (!response?.data) {
          console.error('[account/getAccount]: No account found')
          return
        }
        commit('setAccount', response.data)
      } catch (error) {
        if (!error?.response?.data) {
          console.error('[account/getAccount]: No account found')
          return
        }
        commit('setAccount', error?.response?.data ?? null)
      }
    }
  }
}
