/**
 * Sysend sends messages between tabs/windows/iframes
 * @see https://github.com/jcubic/sysend.js
 *
 * import sysend from 'sysend'
 */
let sysend
try {
  sysend = require('sysend')
} catch(e) {
  sysend = {
    on: () => {
      console.warn('Sysend on was called, but is not installed. Check package.json')
    },
    off: () => {},
    broadcast: () => {},
    emit: () => {},
    proxy: () => {},
    serializer: () => {}
  }
}

export default ({ app }, inject) => {
  inject('sysend', sysend)
}
