import { Store, Module } from 'vuex'
import { CentraProduct } from '@made-people/centra-models'
import CentraProductModule, { getLocalizedValue, getProductPrice, transformProduct as _transformProduct } from '@made-people/centra-storyblok-nuxt-shared/lib/store/centra-product'
import { RootState } from './types.js'
import logError from '~/utils/log-error'

export const transformProduct = (store: Store<RootState['centra-cart']>, product: CentraProduct) => {
  if (!product) {
    return null
  }

  if (product._projectTransformed) {
    return product
  }

  const frontendProduct = _transformProduct(store, product)
  if (!frontendProduct) {
    return null
  }

  return {
    ...frontendProduct,
    _projectTransformed: true,
    priceBeforeDiscountAsNumber: getProductPrice(store, product, 'priceBeforeDiscountAsNumber'),
    currencyPrefix: store.getters['frontend/pricelist'].currency.prefix,
    currencySuffix: store.getters['frontend/pricelist'].currency.suffix,
    badgeDescription: getLocalizedValue(store, product, 'pr_badge'),
    videoUrl: getLocalizedValue(store, product, 'pr_video_url'),
    videoImageUrl: product.pr_video_image_url,
    relatedProducts: product.relatedProducts?.map(relatedProduct => ({
      ...relatedProduct,
      name: getLocalizedValue(store, relatedProduct, 'name') as string
    })) ?? [],
    metaTitle: product.metaTitle,
    metaDescription: product.metaDescription,
    washingAdvice: getLocalizedValue(store, product, 'washing_advice'),
    material: getLocalizedValue(store, product, 'material_pdp'),
    hook: getLocalizedValue(store, product, 'hooks_and_eye'),
    outOfStockMessage: product.out_of_stock_message,
    wiring: getLocalizedValue(store, product, 'wiring'),
    sku: product.sku,
    productSku: product.productSku,
    discontinued: product.discontinued,
    silkVariant: product.silkVariant
  }
}

export default {
  ...CentraProductModule,
  mutations: {
    ...CentraProductModule.mutations,
    products (state, products: CentraProduct[]) {
      state.products = products.concat(state.products.filter((item) => {
        return !products.find(product => product.product === item.product)
      }))
    }
  },
  actions: {
    ...CentraProductModule.actions,
    /**
     * Looks up product by sku from centra
     */
    async lookupProductBySku ({ commit }, sku) {
      try {
        const productRes = await this.$backendApi.get<CentraProduct>(`/products/by-sku/${sku}`)
        if (!productRes?.data) {
          throw new Error('Could not find product')
        }
        const product = productRes.data
        commit('products', [product])
        return product
      } catch (error) {
        logError.call(this, error, `Error in centra-product/lookupProductBySku (${sku}): `)
      }
    }
  },
} as Module<RootState['centra-product'], RootState>
