import axios from 'axios'

/**
 * Don't know how to make this into a separate file in a nuxt module
 */
export function backendClientInit (app, store, marketGetter, pricelistGetter, countryGetter, languageGetter, campaignGetter, ssrHeaderGetter) {
  const backendApiUrl = app.$config.backendApiUrl

  const client = axios.create({
    timeout: 30000,
    baseURL: backendApiUrl,
    withCredentials: true
  })

  const campaign = app.$cookies.get('campaign')
  if (campaign) {
    store.dispatch('frontend/setCurrentCampaignUri', campaign)
  }

  client.interceptors.response.use((response) => {
    if (response.headers['x-context-market']) {
      store.dispatch('frontend/setCurrentContext', {
        market: response.headers['x-context-market'],
        language: response.headers['x-context-language'],
        country: response.headers['x-context-country']
      })
    }
    return response
  })

  // Send getters on every request
  client.interceptors.request.use((request) => {
    request.params = request.params || {}
    request.params.market = marketGetter()
    request.params.country = countryGetter()
    request.params.language = languageGetter()
    request.params.campaign = campaignGetter()
    request.params.pricelist = pricelistGetter()
    if (ssrHeaderGetter) {
      const ssrHeaders = ssrHeaderGetter()
      if (ssrHeaders) {
        for (const [header, value] of Object.entries(ssrHeaders)) {
          if (header === 'host' || /^x-forwarded-/.test(header)) {
            request.headers[header] = value
          }
        }
      }
    }
    return request
  }, error => Promise.reject(error))

  return client
}

/**
 * This registers the centra API client and dispatches the initialize action
 * to set up market, cart and so on
 *
 * We should definitely explode if the config doesn't exist but the plugin is registered.
 *
 * @param app
 * @param inject
 */
export default async (context, inject) => {
  const { app, store } = context

  const backendApi = backendClientInit(
    app,
    store,
    () => store.getters['frontend/currentMarketId'],
    () => store.getters['frontend/currentPricelistId'],
    () => store.getters['frontend/currentCountryCode'],
    () => store.getters['frontend/currentLanguageCode'],
    () => store.getters['frontend/currentCampaign'],
    () => store.getters['frontend/ssrHeaders']
  )
  inject('backendApi', backendApi)

  if (process.client) {
    // Browser side initialization
    if (window.Worker || /\/checkout/.test(context.route.fullPath)) {
      // Fetch cart in worker directly (if available)
      // Only block page rendering with fetching cart if we're on the checkout page
      await store.dispatch('centra-cart/initialize', context.route)
    }
  } else {
    // Server side initialization
    store.commit('frontend/ssrHeaders', context.ssrContext.req.headers, { root: true })

    // Just so we can disable this if we handle the context in another place such as
    // for miss mary
    if (!process.env.DISABLE_BACKEND_PLUGIN_STATE_FETCH) {
      const response = await backendApi.get('contexts')
      store.commit('centra-market/markets', response.data.markets, { root: true })
      store.commit('centra-pricelist/pricelists', response.data.pricelists, { root: true })
      store.commit('frontend/countries', response.data.countries, { root: true })
      store.commit('frontend/languages', response.data.languages, { root: true })
    }
  }
}
