
import { Actions, triggerAction } from '~/lib/actions'

export default {
  name: 'MpLink',
  props: {
    to: {
      type: [String, Object],
      default: undefined,
    },
  },
  computed: {
    finalTo () {
      const to = this.to

      // Add UTM tracking params to URL, so they persist when navigating the
      // site. This increases the attribution accuracy in ad platforms.
      if (typeof to === 'string' && !/^(http[s]?|mailto):/.test(to)) {
        return this.$store.getters['frontend/getPathWithWhitelistedQueryParams'](to)
      }
      return to
    },
    hasCustomAction () {
      return typeof this.finalTo === 'string' && this.finalTo.includes('?custom_action_')
    },
  },
  methods: {
    getCustomActionQueries () {
      const customActionQueries = new URLSearchParams()
      if (typeof this.finalTo === 'undefined' || typeof this.finalTo !== 'string' || !this.finalTo.includes('custom_action_')) {
        return undefined
      }
      // Get query
      const query = new URLSearchParams(this.finalTo.split('/').pop() || '')
      // Get all custom action queries
      for (const [key, value] of query) {
        if (key.startsWith('custom_action')) {
          customActionQueries.append(key, value)
        }
      }
      return customActionQueries
    },
    handleActionButtonClick () {
      for (const [actionName, actionValue] of this.getCustomActionQueries() ?? []) {
        if (typeof actionName === 'undefined') {
          console.warn('[custom_action]: Couldn\'t find action name')
          continue
        }
        if (typeof actionValue === 'undefined') {
          console.warn('[custom_action]: Couldn\'t find action value')
          continue
        }

        switch (actionName) {
          case Actions.ADD_TO_CART: {
            this.handleAddToCartAction(actionValue)
            break
          }
          default: {
            console.warn('[custom_action]: Couldn\'t find action')
            break
          }
        }
      }
    },
    handleAddToCartAction (idStr) {
      try {
        const id = parseInt(idStr)
        if (Number.isNaN(id)) {
          throw new TypeError('[custom_action_add_to_cart]: Wrong input')
        }

        triggerAction(Actions.ADD_TO_CART, id)
      } catch (_err) {
        const err = _err
        if (!(err instanceof TypeError)) {
          console.warn(err.message)
        }
      }
    }
  },
}
