
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'YotpoController',
  data () {
    return {
      yotpoAppliedPoints: '',
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
    }),
    ...mapGetters({
      cart: 'centra-cart/cart',
    }),
    accountKey () {
      const accountParts = []
      if (this.account !== null && this.account.isLoggedIn) {
        accountParts.push('account')
      }
      if (this.cart) {
        accountParts.push('cart')
      }
      return accountParts.join('-')
    },
  },
  beforeDestroy () {
    document.body.removeEventListener('yotpoLoyaltyDiscountApplied', this.handleLoyaltyCoupon)
  },
  mounted () {
    document.body.addEventListener('yotpoLoyaltyDiscountApplied', this.handleLoyaltyCoupon)
  },
  methods: {
    handleLoyaltyCoupon (event) {
      this.$store.dispatch('centra-cart/addVoucher', event.detail.discountCode).then(() => {
        this.yotpoAppliedPoints = event.detail.costInPoints
      })
    },
  }
}
