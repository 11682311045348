import Vue from 'vue'
import { CentraSearchController } from '@made-people/nuxt-centra-search'

Vue.config.ignoredElements = ['order-history-app', 'inretrn-returns']
/* eslint-disable */
// * * * * * Main views
Vue.component('product', () => import('~/views/Product.vue'))
Vue.component('search', () => import('~/views/Search.vue'))
Vue.component('storyblok', () => import('~/views/Storyblok.vue'))

// * * * * * External components
Vue.component('VueSlickCarousel', () => import('vue-slick-carousel'))
Vue.component('CentraSearchController', CentraSearchController.default)
Vue.component('CentraProductList', () => import('~/components/product-list/CentraProductList.vue'))


// * * * * * Storyblok components
Vue.component('AccordionBlock', () => import('~/components/accordion-block/AccordionBlock.vue'))
Vue.component('AccordionItem', () => import('~/components/accordion-block/AccordionItem.vue'))
Vue.component('BlogCollection', () => import('~/components/blog/BlogCollection.vue'))
Vue.component('BlogListItem', () => import('~/components/blog/BlogListItem.vue'))
Vue.component('BlogPageInfo', () => import('~/components/blog/BlogPageInfo.vue'))
Vue.component('BlogPagination', () => import('~/components/blog/BlogPagination.vue'))
Vue.component('BlogTags', () => import('~/components/blog/BlogTags.vue'))
Vue.component('Button', () => import('~/components/button/Button.vue'))
Vue.component('CountryContainer', () => import('@made-people/nuxt-storyblok-country-container'))
Vue.component('FacetInfoContainer', () => import('~/components/facet-info/FacetInfoContainer.vue'))
Vue.component('FacetInfoContainerCollection', () => import('~/components/facet-info/FacetInfoContainerCollection.vue'))
Vue.component('Grid', () => import('~/components/grid/Grid.vue'))
Vue.component('GrowthbookExperimentContainer', () => import('~/components/growthbook/GrowthbookExperimentContainer.vue'))
Vue.component('Headline', () => import('~/components/headline/Headline.vue'))
Vue.component('Hero', () => import('~/components/hero/Hero.vue'))
Vue.component('HeroReworked', () => import('~/components/hero/HeroReworked.vue'))
Vue.component('ImageBlock', () => import('~/components/image-block/ImageBlock.vue'))
Vue.component('ImageTextBlock', () => import('~/components/image-text-block/ImageTextBlock.vue'))
Vue.component('ImageTextBlockBlog', () => import('~/components/image-text-block/ImageTextBlockBlog.vue'))
Vue.component('ImageTextBlockBlogWrapper', () => import('~/components/image-text-block/ImageTextBlockBlogWrapper.vue'))
Vue.component('ImageTextBlockCatalogue', () => import('~/components/image-text-block/ImageTextBlockCatalogue.vue'))
Vue.component('ImageTextBlockWrapper', () => import('~/components/image-text-block/ImageTextBlockWrapper.vue'))
Vue.component('MarketContainer', () => import('@made-people/centra-storyblok-nuxt-shared/lib/components/MarketContainer.vue'))
Vue.component('MatchingProduct', () => import('~/components/matching-products/MatchingProduct.vue'))
Vue.component('MatchingProducts', () => import('~/components/matching-products/MatchingProducts.vue'))
Vue.component('MatchingSwimwearProducts', () => import('~/components/matching-swimwear/MatchingSwimwearProducts.vue'))
Vue.component('MatchingSwimwearProduct', () => import('~/components/matching-swimwear/MatchingSwimwearProduct.vue'))
Vue.component('MpLink', () => import('~/components/mp-link/MpLink.vue'))
Vue.component('Newsletter', () => import('~/components/newsletter/Newsletter.vue'))
Vue.component('ProductList', () => import('~/components/product-list/ProductList.vue'))
Vue.component('ProductListCategoriesCollection', () => import('~/components/product-list/categories-collection/ProductListCategoriesCollection.vue'))
Vue.component('ProductListController', () => import('~/components/product-list/ProductListController.vue'))
Vue.component('QuickShop', () => import('~/components/quick-shop/QuickShop.vue'))
Vue.component('InRetrnWidget', () => import('~/components/inretrn/InRetrnWidget.vue'))
Vue.component('Slider', () => import('~/components/slider/Slider.vue'))
Vue.component('TextBlock', () => import('~/components/text-block/TextBlock.vue'))
Vue.component('Title', () => import('~/components/title/Title.vue'))
Vue.component('Video', () => import('~/components/video/Video.vue'))
Vue.component('WashingsWarranty', () => import('~/components/washings-warranty/WashingsWarranty.vue'))
Vue.component('YotpoPictureGallery', () => import('~/components/yotpo/YotpoPictureGallery.vue'))
Vue.component('YoutubeVideo', () => import('~/components/video/YoutubeVideo.vue'))

// Components that only render for logged in users
Vue.component('ComfortClubAccountWelcome', () => import('~/components/comfort-club/comfort-club-account-welcome/ComfortClubAccountWelcome.vue'))
Vue.component('ComfortClubAccount', () => import('~/components/comfort-club/comfort-club-account/ComfortClubAccount.vue'))
Vue.component('ComfortClubSupportShortcut', () => import('~/components/comfort-club/comfort-club-shortcuts/ComfortClubSupportShortcut.vue'))
Vue.component('ComfortClubOrdersShortcut', () => import('~/components/comfort-club/comfort-club-shortcuts/ComfortClubOrdersShortcut.vue'))
/* eslint-enable */
