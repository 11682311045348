
import { mapGetters, mapState } from 'vuex'
import LazyHydrate from 'vue-lazy-hydration'
import LogoIcon from '~/assets/icons/logo.svg'
import ToggleCart from '~/components/header/ToggleCart'
import ToggleMenu from '~/components/header/ToggleMenu'
import ToggleSearch from '~/components/header/ToggleSearch'

export default {
  name: 'Header',
  components: {
    LazyHydrate,
    LogoIcon,
    ToggleCart,
    ToggleMenu,
    ToggleSearch,
  },
  data () {
    return {
      stuck: false,
      offset: 100,
      scrollpos: 0,
      hover: false,
      showSubmenu: false,
      showSearch: false
    }
  },
  computed: {
    ...mapState({
      searchOpen: state => state.ui.searchOpen,
      desktopSubmenuOpen: state => state.ui.desktopSubmenuOpen,
    }),
    ...mapGetters({
      yotpoSettings: 'yotpo/settings',
      currentDomain: 'frontend/currentDomain',
      cart: 'centra-cart/cart',
      currentPage: 'routes/currentPage',
      getMenuById: 'storyblok/getMenuById',
    }),
    textColorCss () {
      return this.searchOpen ? { '--text-color': '$black' } : { '--text-color': this.textColor }
    },
    textColor () {
      return this.currentPage?.headerTextColor
    },
    mainMenu () {
      return this.getMenuById('main-menu')?.content
        ? this.getMenuById('main-menu').content
        : {
            primaryMenu: [],
            desktopMenu: [],
            additionalMobileMenu: [],
            additionalDesktopMenu: []
          }
    },
  },
  mounted () {
    window.addEventListener('scroll', this.stickyHeader, { capture: true, passive: true })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.stickyHeader)
  },
  methods: {
    showDesktopSubmenu () {
      this.$store.dispatch('ui/showDesktopSubmenu')
      this.showSubmenu = true
    },
    hideDesktopSubmenu () {
      this.$store.dispatch('ui/hideDesktopSubmenu')
      this.showSubmenu = false
    },
    stickyHeader () {
      this.scrollpos = window.scrollY
      this.stuck = this.scrollpos >= this.offset
    }
  }
}
