
import { mapGetters, mapActions } from 'vuex'
import SelectedIcon from '~/assets/icons/selected.svg'

export default {
  name: 'CustomisationFeatures',
  components: {
    SelectedIcon,
  },
  props: {
    view: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      currentProduct: this.product,
      selectedFeature: {
        sidewing: this.product?.hasSideWing,
        lace: this.product?.hasLace,
      },
      isOpen: true,
    }
  },
  computed: {
    ...mapGetters({
      generalSettings: 'storyblok/settings',
      getCentraProduct: 'centra-product/getProductById',
    }),
  },
  methods: {
    ...mapActions({
      lookupProduct: 'centra-product/lookupProducts',
    }),
    async toggleProduct (feature) {
      // Get the new product based on the feature toggled
      let relatedProductId

      if (feature === 'sidewing') {
        relatedProductId = this.currentProduct?.relatedSideWingProducts[0]?.product
      } else if (feature === 'lace') {
        relatedProductId = this.currentProduct?.relatedLaceProducts[0]?.product
      }

      // Update the current product with the new product
      if (relatedProductId) {
        await this.updateCurrentProduct(relatedProductId)
      }

      // Update the feature states and pass on the product url
      this.updateCurrentFeatureState(feature)
      this.currentUrl()

      // Check and update notice status after toggle
      this.checkAndShowNotice()
    },
    relatedProductExists (feature) {
      const featureMap = {
        sidewing: 'relatedSideWingProducts',
        lace: 'relatedLaceProducts'
      }
      const relatedProducts = this.currentProduct?.[featureMap[feature]] || []
      return !!relatedProducts?.length
    },
    checkAndShowNotice () {
      const hasSidewingProducts = this.relatedProductExists('sidewing')
      const hasLaceProducts = this.relatedProductExists('lace')

      // Show notice if either related products are missing
      if (!hasSidewingProducts || !hasLaceProducts) {
        this.showNotice(true)
      } else {
        this.showNotice(false)
      }
    },
    async getProduct (productId) {
      // Get the product from the store, or lookup the product if it doesn't exist
      let product = this.getCentraProduct(productId)
      if (!product) {
        await this.lookupProduct(productId)
        product = this.getCentraProduct(productId)
      }
      return product
    },
    async updateCurrentProduct (productId) {
      const product = await this.getProduct(productId)
      this.currentProduct = product
    },
    updateCurrentFeatureState (feature) {
      this.$set(this.selectedFeature, feature, !this.selectedFeature[feature])
    },
    featureIsSelected (feature) {
      return this.selectedFeature[feature.feature_name]
    },
    currentUrl () {
      this.$emit('update:url', this.currentProduct?.url)
    },
    showNotice (value) {
      this.$emit('show-notice', value)
    },
    startTransition (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    endTransition (el) {
      el.style.height = ''
    },
  },
}
