
import { mapGetters } from 'vuex'
export default {
  name: 'TrustpilotWidget',
  props: {
    fontColor: {
      type: String,
      required: false,
      default: 'black'
    }
  },
  head () {
    return {
      script: [
        {
          type: 'text/javascript',
          src: 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
          async: true,
          defer: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      countrySpecificInformation: 'storyblok/getCountrySpecificInformation',
      countryCode: 'frontend/currentCountryCode'
    }),
    trustpilotWidgetLanguageCode () {
      return this.countrySpecificInformation?.content?.TrustpilotWidget[0]?.dataLocale || 'en-US'
    },
    theme () {
      return this.fontColor === 'white' ? 'dark' : 'light'
    },
    countrySpecificUrlPrefix () {
      if (this.countryCode === 'GB') {
        return 'uk'
      }
      return this.countrySpecificInformation?.content?.TrustpilotWidget?.[0]?.dataLocale?.slice(3) || 'www'
    }
  },
  mounted () {
    this.$nextTick(() => {
      const trustbox = this.$refs.trustpilotWidget
      window?.Trustpilot?.loadFromElement(trustbox)
    })
  }
}
