const getCountrySpecificInformationId = (language, marketId) => ['country-information', marketId, language].join('-')
export const getBannersId = (language, marketId) => ['banners', marketId, language].join('-')
const getWashingInstructionsId = (language, marketId) => ['washing-instructions', marketId, language].join('-')
const getSizeGuideId = (language, countryCode) => ['size-guide', countryCode, language].join('-')
export const getLoyaltyRulesId = countryCode => ['loyalty-market', countryCode.toLowerCase()].join('-')

export default {
  state () {
    return {
      stories: []
    }
  },
  mutations: {
    story: (state, payload) => {
      state.stories = [payload].concat(state.stories.filter(x => x.id !== payload.id))
    }
  },
  actions: {
    fetchCountrySpecificInformation ({ commit, rootState, rootGetters }) {
      return this.$storyapi.get('cdn/stories', {
        params: {
          starts_with: `${rootState.frontend.currentLanguageCode}/shared-content/`,
          filter_query: {
            component: { in: 'CountryInformation' },
            countries: {
              in_array: rootGetters['frontend/currentCountryCode']
            }
          }
        }
      }).then((response) => {
        const data = response.data.stories.flatMap(story => story?.content)
          .filter(x => x !== undefined)
        commit('story', {
          id: getCountrySpecificInformationId(rootState.frontend.currentLanguageCode, rootGetters['frontend/currentCountryCode']),
          data
        })
        return data
      }).catch((error) => {
        console.error('[missmary-storyblok/fetchCountrySpecificInformation]: ', error)
      })
    },
    fetchWashingInstructions ({ commit, rootState, rootGetters }) {
      return this.$storyapi.get('cdn/stories', {
        params: {
          starts_with: `${rootState.frontend.currentLanguageCode}/shared-content/`,
          filter_query: {
            component: { in: 'WashingInstructions' },
            markets: {
              in_array: rootGetters['frontend/currentMarketId']
            }
          }
        }
      }).then((response) => {
        const data = response.data.stories.flatMap(story => story?.content?.content)
          .filter(x => x !== undefined)
        commit('story', {
          id: getWashingInstructionsId(rootState.frontend.currentLanguageCode, rootGetters['frontend/currentMarketId']),
          data
        })
        return data
      }).catch((error) => {
        console.error('[missmary-storyblok/fetchWashingInstructions]: ', error)
      })
    },
    fetchSizeGuide ({ commit, rootState, rootGetters }) {
      return this.$storyapi.get('cdn/stories', {
        params: {
          starts_with: `${rootState.frontend.currentLanguageCode}/shared-content/`,
          filter_query: {
            component: { in: 'SizeGuide' },
            countries: {
              in_array: rootGetters['frontend/currentCountryCode']
            }
          }
        }
      }).then((response) => {
        const data = response.data.stories.flatMap(story => story?.content)
          .filter(x => x !== undefined)
        commit('story', {
          id: getSizeGuideId(rootState.frontend.currentLanguageCode, rootGetters['frontend/currentCountryCode']),
          data
        })
        return data
      }).catch((error) => {
        console.error('[missmary-storyblok/fetchSizeGuide]: ', error)
      })
    },
    fetchLoyaltyRules ({ commit, rootState }) {
      return this.$backendApi('content/loyalty-market'
      ).then((response) => {
        const data = response.data.content
        commit('story', {
          id: getLoyaltyRulesId(rootState.frontend.currentCountryCode),
          data
        })
      }).catch((error) => {
        console.error('[missmary-storyblok/fetchLoyaltyRules]: ', error)
      })
    },
  },
  getters: {
    getCountryInformation: state => (languageCode, marketId) => state.stories.find(it => it.id === getCountrySpecificInformationId(languageCode, marketId)) || { id: null, data: [] },
    getBanners: state => (languageCode, marketId) => state.stories.find(it => it.id === getBannersId(languageCode, marketId)) || [],
    getWashingInstructions: state => (languageCode, marketId) => state.stories.find(it => it.id === getWashingInstructionsId(languageCode, marketId)) || { id: null, data: [] },
    getSizeGuide: state => (languageCode, countryCode) => state.stories.find(it => it.id === getSizeGuideId(languageCode, countryCode)) || { id: null, data: [] },
    getLoyaltyRules: state => countryCode => state.stories.find(it => it.id === getLoyaltyRulesId(countryCode)) || { id: null, data: [] },
  }
}
