
import Nl2br from '~/mixins/nl2br.js'

export default {
  name: 'Headline',
  mixins: [Nl2br],
  props: {
    text: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'h2'
    },
    size: {
      type: String,
      required: false,
      default: 'h--xx-large'
    },
    color: {
      type: String,
      required: false,
      default: '#383838'
    },
    alignText: {
      type: String,
      required: false,
      default: 'center'
    },
    padding: {
      type: String,
      required: false,
      default: '--none'
    }
  }
}
