export enum WorkerEventTopic {
  Error = 'ERROR',
  Acknowledge = 'ACK',
  Request = 'REQUEST',
  Data = 'DATA',
}

/**
 * Sent from main thread to worker to initiate execution
 */
export interface WorkerEventError<Payload extends Record<string, unknown>> {
  topic: WorkerEventTopic.Error,
  payload: Payload
}
export function isWorkerEventError<Payload extends Record<string, unknown>> (x: any, payloadValidator?: (x: any) => x is Payload): x is WorkerEventError<Payload> {
  let result = isWorkerEvent(x) && x.topic === WorkerEventTopic.Error
  if (typeof payloadValidator === 'function') {
    result = result && payloadValidator(x.payload)
  }
  return result
}

/**
 * Sent from either main thread or worker to acknowledge that the thread received data of type
 */
export interface WorkerEventAcknowledge<EventType extends string | number> {
  topic: WorkerEventTopic.Acknowledge
  type: EventType
}
export function isWorkerEventAcknowledge<EventType extends string | number> (x: any, eventType: EventType): x is WorkerEventAcknowledge<EventType> {
  return isWorkerEvent(x) && x.topic === WorkerEventTopic.Acknowledge && x.type === eventType
}

/**
 * Sent from either main thread or worker to notify that the thread is ready to receive data of type
 */
export interface WorkerEventRequest<EventType extends string | number> {
  topic: WorkerEventTopic.Request
  type: EventType
}
export function isWorkerEventRequest<EventType extends string | number> (x: any, eventType: EventType): x is WorkerEventRequest<EventType> {
  return isWorkerEvent(x) && x.topic === WorkerEventTopic.Request && x.type === eventType
}

/**
 * Sent from worker to main thread and contains the data
 */
export interface WorkerEventData<Data, EventType extends string | number> {
  topic: WorkerEventTopic.Data
  type: EventType
  data: Data
}
export function isWorkerEventData<DataType, EventType extends string | number> (x: any, eventType: EventType, dataValidator?: (x: any) => x is DataType): x is WorkerEventData<DataType, EventType> {
  let result = isWorkerEvent(x) && x.topic === WorkerEventTopic.Data && 'data' in x && x.type === eventType
  if (typeof dataValidator === 'function') {
    result = result && dataValidator(x.data)
  }
  return result
}

export type WorkerEvent<Data = never, EventType extends string | number = never, Payload extends Record<string, unknown> = never> = WorkerEventError<Payload> | WorkerEventAcknowledge<EventType> | WorkerEventRequest<EventType> | WorkerEventData<Data, EventType>
export function isWorkerEvent<DataType, EventType extends string | number> (x: any): x is WorkerEvent<DataType, EventType> {
  return typeof x === 'object' && x !== null && Object.values(WorkerEventTopic).includes(x.topic)
}

export function createWorkerEvent<Payload extends Record<string, unknown> = never> (topic: Extract<WorkerEventTopic, WorkerEventTopic.Error>, payload: Payload): Extract<WorkerEvent<never, never, Payload>, { topic: WorkerEventTopic.Error }>
export function createWorkerEvent<EventType extends string | number = never> (topic: Extract<WorkerEventTopic, WorkerEventTopic.Acknowledge>, eventType: EventType): Extract<WorkerEvent<never, EventType, never>, { topic: WorkerEventTopic.Acknowledge }>
export function createWorkerEvent<EventType extends string | number = never> (topic: Extract<WorkerEventTopic, WorkerEventTopic.Request>, eventType: EventType): Extract<WorkerEvent<never, EventType, never>, { topic: WorkerEventTopic.Request }>
export function createWorkerEvent<Data = never, EventType extends string | number = never> (topic: Extract<WorkerEventTopic, WorkerEventTopic.Data>, eventType: EventType, data: Data): Extract<WorkerEvent<Data, EventType>, { topic: WorkerEventTopic.Data }>
export function createWorkerEvent<Data = never, EventType extends string | number = never, Payload extends Record<string, unknown> = never> (topic: WorkerEventTopic, eventTypeOrPayload?: EventType | Payload, data?: Data): WorkerEvent<Data, EventType, Payload> {
  switch (topic) {
    case WorkerEventTopic.Error:
      if (typeof eventTypeOrPayload === 'undefined') {
        throw new TypeError('Payload is undefined')
      }
      return { topic, payload: eventTypeOrPayload as Payload }
    case WorkerEventTopic.Acknowledge:
    case WorkerEventTopic.Request:
      return { topic, type: eventTypeOrPayload as EventType }
    case WorkerEventTopic.Data:
      if (typeof data === 'undefined' || typeof eventTypeOrPayload === 'undefined') {
        throw new TypeError('Data is undefined')
      }
      return { topic, type: eventTypeOrPayload as EventType, data }
    default:
      throw new TypeError(`Topic ${topic} is unknown`)
  }
}
