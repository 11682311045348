import { render, staticRenderFns } from "./FilterAccordionItem.vue?vue&type=template&id=1c72a32a&scoped=true"
import script from "./FilterAccordionItem.vue?vue&type=script&lang=js"
export * from "./FilterAccordionItem.vue?vue&type=script&lang=js"
import style0 from "./FilterAccordionItem.vue?vue&type=style&index=0&id=1c72a32a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c72a32a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Title: require('/usr/src/app/components/title/Title.vue').default,AccordionFilterOption: require('/usr/src/app/components/product-filter/AccordionFilterOption.vue').default})
