
import Nl2br from '~/mixins/nl2br.js'

export default {
  name: 'TextBlock',
  mixins: [Nl2br],
  props: {
    headline: {
      type: Array,
      required: false,
      default: () => ([])
    },
    text: {
      type: Object,
      required: true,
      default: () => ({})
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#FAF9F6'
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: null
    },
    textColor: {
      type: String,
      required: false,
      default: '#383838'
    },
    alignText: {
      type: String,
      required: false,
      default: 'center'
    },
    padding: {
      type: String,
      required: false,
      default: '--none'
    },
    horizontalPadding: {
      type: String,
      required: false,
      default: '--medium'
    },
    desktopIndentation: {
      type: String,
      required: false,
      default: ''
    },
    verticalAlignment: {
      type: String,
      required: false,
      default: ''
    },
    showMoreButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      showMore: !this.showMoreButton
    }
  },
  computed: {
    sanatiseText () {
      return this.text.html.replace('/site', '')
    },
    backgroundColorValue () {
      return this.backgroundColorHex || this.backgroundColor
    },
    showMoreBtnLabel () {
      return this.showMore ? this.$t('Product_Details_Show_Less') : this.$t('Product_Details_Show_More')
    }
  },
  methods: {
    truncate () {
      this.showMore = !this.showMore
    }
  }
}
