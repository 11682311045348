export default {
  state: () => ({
    trackingInfo: undefined
  }),
  mutations: {
    trackingInfo (state, trackingInfo) {
      state.trackingInfo = trackingInfo
    }
  },
  actions: {
    setTrackingInfo ({ commit }, trackingInfo) {
      commit('trackingInfo', trackingInfo)
    },
    // Remove tracking info from the store
    clearTrackingInfo ({ commit }) {
      commit('trackingInfo', undefined)
    }
  },
  getters: {
    getTrackingInfo: state => state.trackingInfo
  }
}
