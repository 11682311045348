import * as imgproxy from '@made-people/imgproxy'

export default ({ app, $config }, inject) => {
  inject('imgproxy', {
    transform: (url, definition) => {
      imgproxy.useBaseUrl($config.imageProxyUrl)
      return imgproxy.transform(url, definition)
    }
  })
}
