import logError from '~/utils/log-error'

/**
 * Plugin that runs client side and calls the backend with google analytics tracking info, if set.
 *
 * This plugin is required because the google analytics request to the backend cannot be done during SSR because
 * the cookie set will not propagate to the client.
 *
 * This plugin cannot work alone because in nuxt headers are not available in client side 'app's and we use the
 * referrer header.
 *
 * @param context
 */
export default function (context, inject) {
  inject('googleAnalyticsToServer', {
    send: sendToServer.bind(context)
  })

  if (process.server) {
    return
  }

  const delayInMilliseconds = 100
  /**
   * Run retries for this number of milliseconds
   *
   * @type {number}
   */
  const maxRetryTimeInMilliseconds = 5 * 1000

  function sendToServer (retries) {
    const gtag = window.gtag
    const cookiebot = window.Cookiebot

    // Do not send tracking info if user has not accepted marketing or statistics cookies
    if (!(cookiebot?.consent?.marketing || cookiebot?.consent?.statistics)) {
      return
    }

    if (!gtag) {
      const totalRunTime = delayInMilliseconds * retries
      if (totalRunTime > maxRetryTimeInMilliseconds) {
        console.debug(`window.gtag not found after ${totalRunTime} ms`)
        return
      }
      setTimeout(() => sendToServer(retries + 1), delayInMilliseconds)
      return
    }

    gtag('get', context.app.$config.googleAnalyticsId, 'session_id', (sessionId) => {
      gtag('get', context.app.$config.googleAnalyticsId, 'client_id', (clientID) => {
        if (!clientID) {
          console.error('GA client id not set')
          context.app.store.dispatch('google-analytics/clearTrackingInfo')
          context.app.$backendApi
            .delete('/ga/session')
            .catch((error) => {
              logError.call(this, error, 'GA: Failed to clear session')
            })
          return
        }
        const trackingInfo = context.app.store.getters['google-analytics/getTrackingInfo'] ?? {}
        // Set trackingInfo in backend
        context.app.$backendApi
          .put('/ga/session', { ...trackingInfo, gaClientId: clientID, gaSessionId: sessionId })
          .then(() => {
            context.app.store.dispatch('google-analytics/clearTrackingInfo')
          })
          .catch((error) => {
            logError.call(this, error, 'GA: Failed to set trackingInfo')
          })
      })
    })
  }

  sendToServer(0)
}
