
import Nl2br from '~/mixins/nl2br.js'

export default {
  name: 'Title',
  mixins: [Nl2br],
  props: {
    text: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'h3'
    },
    size: {
      type: String,
      required: false,
      default: 'h-large'
    },
    textColor: {
      type: String,
      required: false,
      default: '#383838'
    }

  },
}
