// https://v3.vuejs.org/guide/migration/events-api.html#_2-x-syntax
import Vue from 'vue'

export enum Actions {
  ADD_TO_CART = 'custom_action_add_to_cart',
}

class EventController {
  static bus: Vue
  static eventListeners: Set<Actions> = new Set()
}

/* LISTENER */
export function addActionListener (action: Actions.ADD_TO_CART, cb: (productSku: number) => void): void;
export function addActionListener (action: Actions, cb: Function): void {
  if (typeof EventController.bus === 'undefined') {
    EventController.bus = new Vue()
  }
  if (EventController.eventListeners.has(action)) {
    removeActionListener(action)
  }
  EventController.bus.$on(action, cb)
  EventController.eventListeners.add(action)
}

export function removeActionListener (event: Actions): void {
  if (typeof EventController.bus === 'undefined') {
    EventController.bus = new Vue()
  }
  EventController.bus.$off(event)
  if (EventController.eventListeners.has(event)) {
    EventController.eventListeners.delete(event)
  }
}

/* EMITTER */
export function triggerAction (event: Actions.ADD_TO_CART, sku: number): void;
export function triggerAction (event: Actions, payload: unknown): void {
  if (!EventController.eventListeners.has(event)) {
    return
  }
  EventController.bus.$emit(event, payload)
}
