
import { mapGetters, mapActions } from 'vuex'
import FlagIcon from './FlagIcon.vue'

export default {
  name: 'CountrySelectorIcon',
  components: {
    FlagIcon,
  },
  computed: {
    ...mapGetters({
      currentCountry: 'frontend/currentCountry',
      countries: 'frontend/countries',
      pricelist: 'frontend/pricelist'
    }),
    currentCountryToLower () {
      return this.currentCountry.country.toLowerCase()
    },
    currentCurrency () {
      return this.pricelist.currency.currency
    }
  },
  methods: {
    showCountrypopup () {
      this.showCountrySelectorPopup()
    },
    ...mapActions({
      showCountrySelectorPopup: 'ui/showCountrySelectorPopup'
    })
  }
}
