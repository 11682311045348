
export default {
  name: 'FlagIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    flagIcon () {
      return () => import(
        `~/assets/icons/flags/1x1/${this.icon}.svg`
      )
    },
  },
}
