
import CustomisationFeatures from './CustomisationFeatures.vue'
import FeatureNotice from './FeatureNotice.vue'
import CrossIcon from '~/assets/icons/cross.svg'

export default {
  name: 'CustomisationPopup',
  components: {
    CrossIcon,
    CustomisationFeatures,
    FeatureNotice,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isOpen: true,
      activeNotice: false,
      currentUrl: this.product?.url,
    }
  },
  methods: {
    hideCustomisationPopup () {
      this.$store.dispatch('ui/hideCustomisationPopup')
    },
    handleSubmit () {
      if (this.product.url === this.currentUrl) {
        this.hideCustomisationPopup()
      } else {
        this.$router.push(this.currentUrl)
        this.hideCustomisationPopup()
      }
    },
    showNotice (value) {
      this.activeNotice = value
    },
    setIsOpen (isOpen) {
      this.isOpen = isOpen
    },
    setUrl (url) {
      this.currentUrl = url
    },
  },
}
