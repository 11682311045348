
import { mapGetters } from 'vuex'
import CrossIcon from '~/assets/icons/cross.svg'
import CountrySelectorIcon from '~/components/country-selector/CountrySelectorIcon'
import TextBlock from '~/components/text-block/TextBlock'
import logError from '~/utils/log-error'
import PaymentGatewayLogos from '~/components/payment-gateway-logos/PaymentGatewayLogos'

export default {
  name: 'TopBanner',
  components: {
    CrossIcon,
    CountrySelectorIcon,
    TextBlock,
    PaymentGatewayLogos
  },
  props: {
    banner: {
      type: [Object, Array],
      required: true
    },
  },
  data () {
    return {
      hidden: false,
      currentBannerIndex: 0,
    }
  },
  computed: {
    ...mapGetters({
      currentDomain: 'frontend/currentDomain',
      currentCountry: 'frontend/currentCountry',
    }),
    colorsCss () {
      return {
        '--background-color': this.banner?.[0]?.backgroundColor || this.currentBanner?.backgroundColor,
        '--text-color': this.banner?.[0]?.textColor || this.currentBanner?.textColor
      }
    },
    checkbanner () {
      return this.currentBanner?.Textblock?.html && this.currentBanner?.bannerLinkUrl
    },
    currentBanner () {
      return this.bannerIsArray ? this.banner[this.currentBannerIndex] : this.banner
    },
    rotatingBanners () {
      return this.bannerIsArray && this.banner.length > 1
    },
    bannerIsArray () {
      return Array.isArray(this.banner)
    },
    widget () {
      return this.currentBanner?.widget
    },
    trustpilotTheme () {
      const textColor = this.banner?.[0]?.textColor || this.currentBanner?.textColor
      return textColor === '#FFFFFF' ? 'dark' : 'light'
    }
  },
  mounted () {
    if (this.rotatingBanners) {
      setInterval(() => {
        this.currentBannerIndex = (this.currentBannerIndex + 1) % this.banner.length
      }, 5000)
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    close () {
      const bannerId = this?.banner?.[0]?._uid || this?.banner?._uid
      this.hidden = true
      if (bannerId !== undefined) {
        this.$cookies.set(bannerId, 1, {
          path: '/',
          maxAge: 2592000
        })
      } else {
        logError.call(this, 'Banner ID not found', 'Could not set cookie for banner: ')
      }
    }
  }
}
