
import SearchIcon from '~/assets/icons/search.svg'

export default {
  name: 'ToggleSearch',
  components: {
    SearchIcon
  },
  methods: {
    showSearch () {
      this.$store.dispatch('ui/toggleSearch')
    }
  }
}
