/**
 * Traverse the filters and values of an existing response and compare
 * with a new one and its query to find out which filter fields are
 * selected and can be selected.
 */
function prepareFilters({ responseToAdd, query, searchResponse }) {
  return responseToAdd.preparedFilters.map((filter) => ({
    ...filter,
    values: filter.values?.map((value) => {
      const selected = filter.field
        ? !!query[filter.field]?.includes(value.value)
        : false;

      return {
        ...value,

        // Mark values as selected
        selected,

        // This flag can be used to grey out values
        canBeSelected:
          selected ||
          !!searchResponse.filter
            ?.find((f) => f.field === filter.field)
            ?.values?.find((v) => v.value === value.value),
      };
    }),
  }));
}

export default function centraSearchPlugin({ app: { store } }) {
  store.registerModule("centra-search", {
    namespaced: true,
    state() {
      return {
        searchResponses: {},
      };
    },
    mutations: {
      searchResponse(
        state,
        { searchRequestId, searchResponse, query, resetQuery, resetProducts },
      ) {
        let responseToAdd = {
          ...searchResponse,
          __query: query,
        };

        if (resetQuery) {
          delete state.searchResponses[searchRequestId];
        }

        if (state.searchResponses[searchRequestId]) {
          const mappedProducts = resetProducts
            ? responseToAdd.mappedProducts
            : state.searchResponses[searchRequestId].mappedProducts.concat(
                responseToAdd.mappedProducts,
              );

          // Append new products to the old ones, so we can loadMore
          responseToAdd = {
            ...state.searchResponses[searchRequestId],
            ...responseToAdd,
            mappedProducts,
          };
        } else {
          // Since this is the first request we store the initialQuery,
          // so we can reset filters
          responseToAdd.__initialQuery = responseToAdd.__query;

          responseToAdd.preparedFilters = [...searchResponse.filter];
        }

        // Set flags to determine if filter/values are selected/selectable at all,
        // so we can make checkboxes and grey out options
        responseToAdd.preparedFilters = prepareFilters({
          responseToAdd,
          query,
          searchResponse,
        });

        // Delete the raw filter response and focus on preparedFilters,
        // so we can add active flag and focus on rendering and
        // keep state small
        delete responseToAdd.filter;

        responseToAdd.hasMoreProducts =
          responseToAdd.mappedProducts.length < responseToAdd.productCount;

        state.searchResponses = {
          ...state.searchResponses,
          [searchRequestId]: responseToAdd,
        };
      },
    },
    actions: {
      /**
       * Perform a search on any query
       */
      async search(
        { commit, state, rootGetters },
        { searchRequestId, nextPage, query, resetQuery, resetProducts },
      ) {
        const baseUri = this.$config?.centraSearch?.baseUri ?? "centra-search";

        if (resetProducts || resetQuery) {
          query.__page = 1;
        } else {
          query.__page =
            query.__page ||
            (state.searchResponses[searchRequestId]?.__query?.__page ?? 1);
          if (nextPage) {
            query.__page++;
          }
        }

        try {
          const searchResponse = await this.$backendApi
            .post(baseUri, {
              language: rootGetters["frontend/currentLanguageCode"],
              market: rootGetters["frontend/currentMarketId"],
              pricelist: rootGetters["frontend/currentPricelistId"],
              __country: rootGetters["frontend/currentCountryCode"],
              ...query,
            })
            .then((response) => response.data);
          commit("searchResponse", {
            searchRequestId,
            searchResponse,
            query,
            resetQuery,
            resetProducts,
          });
        } catch (e) {
          console.error(e);
        }
      },

      /**
       * Load the next page of the specified searchRequestId
       */
      async loadMore({ dispatch, state }, { searchRequestId }) {
        const searchResponse = state.searchResponses[searchRequestId];
        if (!searchResponse) {
          console.warn(`No search response found for ${searchRequestId}`);
          return;
        }

        const query = { ...searchResponse.__query };
        query.__page++;
        return dispatch("search", {
          searchRequestId,
          query,
        });
      },

      /**
       * Toggle a filter's value for a searchRequestId
       *
       *   filter: { field: string, value: string }
       *
       */
      async toggleFilter({ dispatch, state }, { searchRequestId, filter }) {
        const searchResponse = state.searchResponses[searchRequestId];
        if (!searchResponse) {
          console.warn(`No search response found for ${searchRequestId}`);
          return;
        }

        const query = { ...searchResponse.__query };
        const isFilterActive = !!query[filter.field]?.find(
          (v) => v === filter.value,
        );

        if (isFilterActive) {
          // Remove filter
          query[filter.field] = query[filter.field].filter(
            (v) => v !== filter.value,
          );
        } else {
          // Add filter
          query[filter.field] = (query[filter.field] || []).concat(
            filter.value,
          );
        }

        return dispatch("search", {
          searchRequestId,
          query,
          resetProducts: true,
        });
      },

      /**
       * Reset all filters of a searchRequestId to their initial values
       */
      async resetFilters({ dispatch, state }, { searchRequestId }) {
        const searchResponse = state.searchResponses[searchRequestId];
        if (!searchResponse) {
          console.warn(`No search response found for ${searchRequestId}`);
          return;
        }

        const query = { ...searchResponse.__initialQuery };
        return dispatch("search", {
          searchRequestId,
          query,
          resetProducts: true,
        });
      },
    },
  });
}
