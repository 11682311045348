// @ts-ignore
import CentraCart from '@made-people/centra-storyblok-nuxt-shared/lib/store/centra-cart'
import { CentraSelection } from '@made-people/centra-models'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from './types'
import { transformProduct } from '~/store/centra-product'
import logError from '~/utils/log-error'

export function state (): RootState['centra-cart'] {
  return {
    ...CentraCart.state(),
    receipt: undefined,
  }
}

export const getters: GetterTree<RootState['centra-cart'], RootState> = {
  ...CentraCart.getters,
  cartQty (_, getters) {
    return getters.cart?.totals?.totalQuantity ?? 0
  },
  grandTotalPriceAsNumber (state) {
    return state.selection?.totals?.grandTotalPriceAsNumber
  },
  receipt (state) {
    return state.receipt
  }
}

export const mutations: MutationTree<RootState['centra-cart']> = {
  ...CentraCart.mutations,
  receipt (state, receipt) {
    state.receipt = receipt
  },
  selection (state, selection: CentraSelection) {
    if (selection && selection.items) {
      selection.items = selection.items.map((item) => {
        // @ts-ignore
        if (item._product) {
        // @ts-ignore
          item._product = transformProduct(this, item._product, this.$config)
        }
        return item
      })
    }
    state.selection = selection
  },
}

export const actions: ActionTree<RootState['centra-cart'], RootState> = {
  ...CentraCart.actions,
  initialize ({ dispatch }, route) {
    if (!/checkout\/result/.test(route.fullPath)) {
      return dispatch('fetchCart')
    }
  },
  async changeCountryAndState ({ commit, dispatch }, { countryCode, stateCode }) {
    dispatch('checkout/startLoading', {}, { root: true })
    const response = await this.$backendApi.put(
      `/cart/country/${countryCode}`,
      stateCode ? { state: stateCode } : {},
    )
    commit('selection', response.data)
    dispatch('checkout/stopLoading', {}, { root: true })
  },
  async changeShipping ({ commit, dispatch }, { shippingMethod }) {
    dispatch('checkout/startLoading', {}, { root: true })
    const response = await this.$backendApi.put(`cart/shipping-method/${shippingMethod}`)
    commit('selection', response.data)
    dispatch('checkout/stopLoading', {}, { root: true })
  },

  /**
   * Clear the cart
   */
  clearCart ({ commit }) {
    commit('selection', null)
    commit('payment', undefined)
    commit('shipping', undefined)
    commit('order', undefined)
  },

  async updateItem ({ commit, dispatch }, { item, quantity }) {
    dispatch('checkout/startLoadingPaypalButton', {}, { root: true })
    dispatch('checkout/startLoadingStripeButton', {}, { root: true })
    dispatch('checkout/_callCheckoutScriptSuspend', {}, { root: true })
    try {
      const response = await this.$backendApi
        .put(`cart/update/${item}/${quantity}`)

      commit('selection', response.data)
      return response.data
    } catch (err) {
      logError.call(this, err, `Failed to update cart item ${item} to ${quantity}`)
      return undefined
    } finally {
      dispatch('checkout/_callCheckoutScriptResume', {}, { root: true })
    }
  },

  async fetchReceipt ({ commit }, selectionId) {
    const response = await this.$backendApi.get(
      `cart/checkout/${selectionId}/success`
    )
    commit('receipt', response.data.receipt)
    commit('order', response.data.order)
  },

  /**
   * Fetch an existing cart and replace it in the state. Since we always get
   * the complete cart we can always replace it without diffing things
   */
  fetchCart ({ commit, rootGetters, dispatch }) {
    const fetchCartNormally = async () => {
      try {
        const response = await this.$backendApi.get('cart')

        if (response.status === 200) {
          commit('selection', response.data)
        } else {
          commit('selection', null)
        }
      } catch (err) {
        // There is no cart or an error, the error should really be handled
        console.error(err)
      }
    }

    if (
      rootGetters['growthbook/gbFeatureIsOn']('worker-selection') &&
      rootGetters['growthbook/getGbFeatureValue']('worker-selection') === true
    ) {
      this.$workerCartInit(
        (selection) => {
          commit('selection', selection)
        },
        (market, language, country) => {
          dispatch('frontend/setCurrentContext', { market, language, country }, { root: true })
        },
        () => {
          // Error callback. If Partytown fetch fails, we fallback to fetching selection regularly
          fetchCartNormally()
        }
      )
    } else {
      fetchCartNormally()
    }
  },
}
