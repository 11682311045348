import Vue from 'vue'
import { DirectiveBinding } from 'vue/types/options'

function isInvalidElement (e: Element) {
  const { top, left, bottom, right } = e.getBoundingClientRect()
  return (
    top === 0 &&
    left === 0 &&
    bottom === 0 &&
    right === 0
  )
}

const clickOutsideEvent = (el: HTMLElement, binding: DirectiveBinding) => (event: MouseEvent) => {
  if (
    isInvalidElement(el) ||
    (event.target && Array.from(el.childNodes).some(e => e.contains(event.target as Node)))
  ) {
    return
  }

  binding.value()
}

// const isActive = false

Vue.directive('click-outside', {
  bind (el: HTMLElement, binding: DirectiveBinding) {
    if (typeof binding.value !== 'function') {
      return
    }
    document.addEventListener('click', clickOutsideEvent(el, binding))
  },
  update (el: HTMLElement, binding: DirectiveBinding) {
    if (typeof binding.value !== 'function') {
      return
    }
    document.addEventListener('click', clickOutsideEvent(el, binding))
  },
  unbind (el, binding) {
    document.removeEventListener('click', clickOutsideEvent(el, binding))
  }
})
