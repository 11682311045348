
import { mapGetters } from 'vuex'

export default {
  name: 'RichTextSchema',
  computed: {
    ...mapGetters({
      market: 'frontend/market',
      currentCountryCode: 'frontend/currentCountryCode',
      currentLanguageCode: 'frontend/currentLanguageCode',
    }),
    jsonLd () {
      const jsonString = JSON.stringify({
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        url: 'https://www.' + this.market.name + '/' + this.currentCountryCode.toLowerCase(),
        name: 'Miss Mary of Sweden',
        potentialAction: {
          '@type': 'SearchAction',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: 'https://www.' + this.market.name + '/' + this.currentCountryCode.toLowerCase() + '/search?q={search_term_string}'
          },
          'query-input': 'required name=search_term_string'
        },
        logo: {
          '@type': 'ImageObject',
          inLanguage: this.currentLanguageCode + '-' + this.currentCountryCode,
          url: 'https://a.storyblok.com/f/111784/2000x1000/ee4806ab56/logo_2x1.png',
          contentUrl: 'https://a.storyblok.com/f/111784/2000x1000/ee4806ab56/logo_2x1.png',
          caption: 'Miss Mary of Sweden'
        },
        sameAs: ['https://www.instagram.com/missmary/']
      })

      return '<script type="application/ld+json">' + jsonString + '<' + '/script>'
    },
  }
}
