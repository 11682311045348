import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _52cff3a8 = () => interopDefault(import('../pages/_context/checkout/index.vue' /* webpackChunkName: "pages/_context/checkout/index" */))
const _250a93b6 = () => interopDefault(import('../pages/_context/comfort-club.vue' /* webpackChunkName: "pages/_context/comfort-club" */))
const _cdea0c8e = () => interopDefault(import('../pages/_context/comfort-club/index.vue' /* webpackChunkName: "pages/_context/comfort-club/index" */))
const _b696c458 = () => interopDefault(import('../pages/_context/comfort-club/account.vue' /* webpackChunkName: "pages/_context/comfort-club/account" */))
const _62807557 = () => interopDefault(import('../pages/_context/comfort-club/account/index.vue' /* webpackChunkName: "pages/_context/comfort-club/account/index" */))
const _0da6af9a = () => interopDefault(import('../pages/_context/comfort-club/account/orders/index.vue' /* webpackChunkName: "pages/_context/comfort-club/account/orders/index" */))
const _62b29cd0 = () => interopDefault(import('../pages/_context/comfort-club/login.vue' /* webpackChunkName: "pages/_context/comfort-club/login" */))
const _4da78a0c = () => interopDefault(import('../pages/_context/comfort-club/register.vue' /* webpackChunkName: "pages/_context/comfort-club/register" */))
const _31380dfc = () => interopDefault(import('../pages/_context/comfort-club/reset-password.vue' /* webpackChunkName: "pages/_context/comfort-club/reset-password" */))
const _bedb34da = () => interopDefault(import('../pages/_context/return-order/index.vue' /* webpackChunkName: "pages/_context/return-order/index" */))
const _04037de4 = () => interopDefault(import('../pages/_context/wishlist.vue' /* webpackChunkName: "pages/_context/wishlist" */))
const _899f0a92 = () => interopDefault(import('../pages/_context/checkout/result.vue' /* webpackChunkName: "pages/_context/checkout/result" */))
const _4d683498 = () => interopDefault(import('../pages/_context/addtocart/_id.vue' /* webpackChunkName: "pages/_context/addtocart/_id" */))
const _565726fe = () => interopDefault(import('../pages/_context/checkout/_selectionId/payment-result.vue' /* webpackChunkName: "pages/_context/checkout/_selectionId/payment-result" */))
const _08738ad4 = () => interopDefault(import('../pages/_context/checkout/_selectionId/success.vue' /* webpackChunkName: "pages/_context/checkout/_selectionId/success" */))
const _4002703f = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/:context?/checkout",
    component: _52cff3a8,
    name: "context-checkout"
  }, {
    path: "/:context?/comfort-club",
    component: _250a93b6,
    children: [{
      path: "",
      component: _cdea0c8e,
      name: "context-comfort-club"
    }, {
      path: "account",
      component: _b696c458,
      children: [{
        path: "",
        component: _62807557,
        name: "context-comfort-club-account"
      }, {
        path: "orders",
        component: _0da6af9a,
        name: "context-comfort-club-account-orders"
      }]
    }, {
      path: "login",
      component: _62b29cd0,
      name: "context-comfort-club-login"
    }, {
      path: "register",
      component: _4da78a0c,
      name: "context-comfort-club-register"
    }, {
      path: "reset-password",
      component: _31380dfc,
      name: "context-comfort-club-reset-password"
    }]
  }, {
    path: "/:context?/return-order",
    component: _bedb34da,
    name: "context-return-order"
  }, {
    path: "/:context?/wishlist",
    component: _04037de4,
    name: "context-wishlist"
  }, {
    path: "/:context?/checkout/result",
    component: _899f0a92,
    name: "context-checkout-result"
  }, {
    path: "/:context?/addtocart/:id?",
    component: _4d683498,
    name: "context-addtocart-id"
  }, {
    path: "/:context?/checkout/:selectionId/payment-result",
    component: _565726fe,
    name: "context-checkout-selectionId-payment-result"
  }, {
    path: "/:context?/checkout/:selectionId/success",
    component: _08738ad4,
    name: "context-checkout-selectionId-success"
  }, {
    path: "/*",
    component: _4002703f,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
