
import * as imgproxy from '@made-people/imgproxy'

const mobileSizes = [320, 360, 375, 414, 640, 720, 750, 848, 768, 1536, 1023, 2046]
const desktopSizes = [
  256, 320, 360, 480, 512, 640, 720, 960, 1024, 1280, 1366, 1440,
  1536, 1680, 1920, 2560, 2880, 3840, 5120,
]

export default {
  name: 'ResponsiveImage',
  props: {
    mobileSrc: {
      type: String,
      default () {
        return null
      },
    },
    desktopSrc: {
      type: String,
      default () {
        return null
      },
    },
    alt: {
      type: String,
      default () {
        return ''
      },
    },
    caption: {
      type: String,
      default () {
        return null
      },
    },
    mobileDisplayWidthOfViewportWidth: {
      type: Number,
      default () {
        return 100
      },
    },
    desktopDisplayWidthOfViewportWidth: {
      type: Number,
      default () {
        return 100
      },
    },
    lazyload: {
      type: Boolean,
      default () {
        return false
      },
    },
    imgSettings: {
      type: Object,
      default () {
        return {}
      },
    },
    imgSettingsDesktop: {
      type: Object,
      default () {
        return {}
      },
    },
    ratio: {
      type: Object,
      default () {
        return {}
      },
    },
    preload: {
      type: Boolean,
      default () {
        return false
      },
    },
    /**
     * Intersection root margin for intersection observer when lazyloading image
     * Defaults to 150px
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#root_margin
     */
    intersectionRootMargin: {
      type: Number,
      default () {
        return 150
      },
    },
    imageFocusPoint: {
      type: String,
      default: null
    },
  },
  data () {
    return {
      showing: !this.lazyload,
      viewportObserver: undefined,
      mobileSizesAttribute: '',
      mobileSrcset: '',
      mobileSrcsetNoWebp: '',
      desktopSizesAttribute: '',
      desktopSrcset: '',
      desktopSrcsetNoWebp: '',
      mergedSizesAttribute: '',
      mergedSrcset: '',
      mergedSrcsetNoWebp: ''
    }
  },
  head () {
    const links = []
    if (this.preload && !this.lazyload && this.mobileSrcset) {
      if (!this.isSvg) {
        if (this.mobileSrc === this.desktopSrcOut) {
          links.push({
            rel: 'preload',
            as: 'image',
            imagesrcset: this.supportsWebP ? this.mergedSrcset : this.mergedSrcsetNoWebp,
            imagesizes: this.mergedSizesAttributeOut,
            fetchpriority: 'high',
          })
        } else {
          links.push({
            rel: 'preload',
            as: 'image',
            href: this.processCDNimage(this.mobileSrc, { width: 650, ...this.imgSettings }),
            imagesrcset: this.supportsWebP ? this.mobileSrcset : this.mobileSrcsetNoWebp,
            imagesizes: this.mobileSizesAttribute,
            fetchpriority: 'high',
            media: '(max-width: 1023px)'
          })
          links.push({
            rel: 'preload',
            as: 'image',
            href: this.processCDNimage(this.desktopSrcOut, { width: 1250, ...this.imgSettings }),
            imagesrcset: this.supportsWebP ? this.desktopSrcset : this.desktopSrcsetNoWebp,
            imagesizes: this.desktopSizesAttribute,
            fetchpriority: 'high',
            media: '(min-width: 1024px)'
          })
        }
      } else {
        links.push({
          rel: 'preload',
          as: 'image',
          href: this.processCDNimage(this.mobileSrc, {
            width: 100,
            ...this.imgSettings,
          }),
        })
      }
    }

    return {
      link: links,
    }
  },
  computed: {
    desktopSettings () {
      return { ...this.imgSettings, ...this.imgSettingsDesktop }
    },
    isSvg () {
      let fileformat
      if (this.mobileSrc) {
        fileformat = this.mobileSrc?.split('.').pop()
      } else {
        fileformat = this.desktopSrc?.split('.').pop()
      }
      return fileformat === 'svg'
    },
    desktopSrcOut () {
      return this.desktopSrc || this.mobileSrc
    },
    /**
     * Check if the image processor supports web p
     * @return {boolean}
     */
    supportsWebP () {
      /**
       * Temporary fix since all images are jpg now,
       * @todo fix this when we have cloudfront support
       */
      return true
    },
    /**
     * Check if we have a ratio, either auto or manually set
     */
    hasRatio () {
      if (this.caption) {
        return false
      }
      if (this.ratio.width && this.ratio.height) {
        return true
      }
      if (this.ratio.type) {
        const dimensions = this.detectDimensions(this.mobileSrc)
        return dimensions
      }
      return false
    },
    /**
     * Calculate image ratio and add as css variables
     * @return {string}
     */
    cssVariables () {
      if (this.ratio.type) {
        const mobile = this.detectDimensions(this.mobileSrc)
        const desktop = this.detectDimensions(this.desktopSrcOut)
        if (mobile && desktop) {
          return (
            '--desktop-ratio: ' +
            (desktop.height / desktop.width) * 100 +
            '%;' +
            '--mobile-ratio: ' +
            (mobile.height / mobile.width) * 100 +
            '%;' +
            '--focusPoint: ' + this.imageFocusPoint + ';'
          )
        }
      }
      if (this.ratio.width && this.ratio.height) {
        const desktopH = this.ratio.desktopHeight || this.ratio.height
        const desktopW = this.ratio.desktopWidth || this.ratio.width
        return (
          '--desktop-ratio: ' +
          (desktopH / desktopW) * 100 +
          '%;' +
          '--mobile-ratio: ' +
          (this.ratio.height / this.ratio.width) * 100 +
          '%;' +
          '--focusPoint: ' + this.imageFocusPoint + ';'
        )
      }
      return '--focusPoint: ' + this.imageFocusPoint + ';'
    },
    config () {
      // Keep the config flat, please
      return this.$config.imgProxy || {}
    },
    mergedSizesAttributeOut () {
      if (
        typeof this.mobileDisplayWidthOfViewportWidth === 'number' && this.mobileDisplayWidthOfViewportWidth < 100 &&
        typeof this.desktopDisplayWidthOfViewportWidth === 'number' && this.desktopDisplayWidthOfViewportWidth < 100
      ) {
        return `(max-width: 1023px) ${this.mobileDisplayWidthOfViewportWidth}vw, ${this.desktopDisplayWidthOfViewportWidth}vw`
      } else if (typeof this.mobileDisplayWidthOfViewportWidth === 'number' && this.mobileDisplayWidthOfViewportWidth < 100) {
        const result = this.mergedSizesAttribute.split(', ').filter((sizeAttribute) => {
          const regExpExecArray = /\((max|min)-width: (\d+)px\)/.exec(sizeAttribute)
          if (regExpExecArray) {
            const [, , sizeRaw] = regExpExecArray
            const size = parseInt(sizeRaw)
            if (size <= 1023) {
              return false
            }
          }
          return true
        })
        result.unshift(`(max-width: 1023px) ${this.mobileDisplayWidthOfViewportWidth}vw`)
        return result.join(', ')
      } else if (typeof this.desktopDisplayWidthOfViewportWidth === 'number' && this.desktopDisplayWidthOfViewportWidth < 100) {
        const result = this.mergedSizesAttribute.split(', ').filter((sizeAttribute) => {
          const regExpExecArray = /\((max|min)-width: (\d+)px\)/.exec(sizeAttribute)
          if (regExpExecArray) {
            const [, , sizeRaw] = regExpExecArray
            const size = parseInt(sizeRaw)
            if (size >= 1023) {
              return false
            }
          } else if (/^\d+px$/.test(sizeAttribute)) {
            return false
          }
          return true
        })
        result.push(`${this.desktopDisplayWidthOfViewportWidth}vw`)
        return result.join(', ')
      } else {
        return this.mergedSizesAttribute
      }
    },
  },
  created () {
    imgproxy.useBaseUrl(this.$config.imageProxyUrl)

    this.mobileSizesAttribute = this.getSizesAttribute(this.mobileSrc)
    this.mobileSrcset = this.getSrcset(this.mobileSrc, this.imgSettings)
    this.mobileSrcsetNoWebp = this.getSrcset(
      this.mobileSrc,
      { webp: true, ...this.imgSettings },
    )

    this.desktopSizesAttribute = this.getSizesAttribute(this.desktopSrcOut, 'desktop')
    this.desktopSrcset = this.getSrcset(this.desktopSrcOut, this.imgSettings, 'desktop')
    this.desktopSrcsetNoWebp = this.getSrcset(
      this.desktopSrcOut,
      { webp: true, ...this.desktopSettings },
      'desktop',
    )

    this.mergedSizesAttribute = this.getSizesAttribute([this.mobileSrc, this.desktopSrcOut], 'both')
    this.mergedSrcset = this.getSrcset([this.mobileSrc, this.desktopSrcOut], this.imgSettings, 'both')
    this.mergedSrcsetNoWebp = this.getSrcset(
      [this.mobileSrc, this.desktopSrcOut],
      { webp: true, ...this.desktopSettings },
      'both',
    )
  },
  mounted () {
    if (!this.showing) {
      this.checkIntersection()
    }
  },
  unmounted () {
    this.viewportObserver?.disconnect()
  },
  methods: {
    /**
     * @param {string | [string, string]} imgSrc
     * @param {"mobile" | "desktop" | "both"} device
     * @return {number[]}
     */
    getSizes (imgSrc, device = 'mobile') {
      let sizes = []
      if (device === 'mobile') {
        sizes = mobileSizes
      } else if (device === 'desktop') {
        sizes = desktopSizes
      } else if (device === 'both') {
        sizes = [...(new Set([...mobileSizes, ...desktopSizes]).values())]
      }
      // Remove upscaled image
      if (Array.isArray(imgSrc) && imgSrc.length === 2) {
        const dimensionsMobile = this.detectDimensions(imgSrc[0])
        const dimensionsDesktop = this.detectDimensions(imgSrc[1])
        if (dimensionsMobile || dimensionsDesktop) {
          sizes = sizes.filter((x) => {
            if (dimensionsMobile && dimensionsDesktop) {
              return x <= dimensionsDesktop.width && x <= dimensionsMobile.width
            } else if (dimensionsMobile) {
              return x <= dimensionsMobile.width
            } else {
              return x <= dimensionsDesktop.width
            }
          }).sort((a, b) => a - b)
        }
      } else if (typeof imgSrc === 'string') {
        const dimensions = this.detectDimensions(imgSrc)
        if (dimensions) {
          sizes = sizes.filter(x => x <= dimensions.width).sort((a, b) => a - b)
        }
      }
      return sizes
    },
    /**
     * Get sizes attribute for <source> sizes
     * @param {string | [string, string]} imgSrc
     * @param {"mobile" | "desktop" | "both"} device
     * @return {string}
     */
    getSizesAttribute (imgSrc, device = 'mobile') {
      const sizes = this.getSizes(imgSrc, device)
      return sizes.map((size, i, arr) => {
        if (i === arr.length - 1) {
          return `${size}px`
        } else {
          return `(max-width: ${(arr[i + 1])}px) ${size}px`
        }
      }).join(', ')
    },
    /**
     * Get all sizes need for <source> srcset
     * @param {string | [string, string]} imgSrc
     * @param {object} settings
     * @param {"mobile" | "desktop" | "both"} device
     * @return {string}
     */
    getSrcset (imgSrc, settings, device = 'mobile') {
      const output = []
      if ((device === 'mobile' || device === 'desktop') && typeof imgSrc === 'string') {
        const sizes = this.getSizes(imgSrc, device)
        for (const w in sizes) {
          output.push(
            this.processCDNimage(imgSrc, { width: sizes[w], ...settings }) +
              ' ' +
              sizes[w] +
              'w',
          )
        }
      } else if (device === 'both' && Array.isArray(imgSrc) && imgSrc.length === 2) {
        let mobileSizes = this.getSizes(imgSrc, 'mobile')
        let desktopSizes = this.getSizes(imgSrc, 'desktop')
        mobileSizes = mobileSizes.filter((size) => {
          if (desktopSizes.includes(size)) {
            return false
          } else if (size >= desktopSizes.slice(-1)[0]) {
            return false
          }
          return true
        })
        desktopSizes = desktopSizes.filter((size) => {
          if (mobileSizes.includes(size)) {
            return false
          } else if (size <= mobileSizes[0]) {
            return false
          }
          return true
        })
        for (const size of mobileSizes) {
          output.push(
            this.processCDNimage(imgSrc[0], { width: size, ...settings }) +
              ' ' +
              size +
              'w',
          )
        }
        for (const size of desktopSizes) {
          output.push(
            this.processCDNimage(imgSrc[1], { width: size, ...settings }) +
              ' ' +
              size +
              'w',
          )
        }
      } else if (device === 'both') {
        throw new TypeError('[getSrcset]: If device is both, imgSrc must be an array of two strings')
      } else {
        return ''
      }

      return output.join(', ')
    },
    processCDNimage (url, attr) {
      return this.processImgproxy(url, attr)
    },
    processImgproxy (url, attr) {
      const quality = attr.quality ? attr.quality : '70'
      let width = attr.width

      if (attr.ratio && attr.ratio > 0) {
        width += ':' + Math.round(attr.width * attr.ratio)
      }

      let definition =
        this.config.preset === false
          ? `resize:fit/width:${width}`
          : `preset:${this.config.preset}/resize:fit:${width}`

      // Fix for storyblok
      url = (url ?? '').replace(/^\/\/a\./, '//img2.')
      url = (url ?? '').replace(/^\/\//, 'https://')

      definition += `/gravity:nowe/quality:${quality}`

      let encoded = imgproxy.transform(url, definition)

      if (this.config.forceJpg) {
        encoded += '.jpg'
      }

      return encoded
    },

    /**
     * Detects dimensions of original image
     * currently only works with storyblok
     * @param url
     * @return {null|{width: *, height: *}}
     */
    detectDimensions (url) {
      if (/a.storyblok.com\//.test(url)) {
        const dimensions = /\/(\d*)x(\d*)\//g.exec(url)
        if (dimensions) {
          return { width: dimensions[1], height: dimensions[2] }
        }
      }
      return null
    },

    // Check if image is in viewport
    checkIntersection () {
      if (this.showing) {
        return
      }
      if (!this.viewportObserver) {
        this.viewportObserver = new IntersectionObserver(
          this.handleIntersect,
          { rootMargin: `${this.intersectionRootMargin}px`, threshold: 0.01 },
        )
      }
      this.viewportObserver.observe(this.$el)
    },
    handleIntersect (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          this.showing = true
          this.viewportObserver?.unobserve(this.$el)
          this.viewportObserver?.disconnect()
        }
      })
    },
    imageLoaded () {
      this.$emit('imageLoaded')
    },
  },
}
