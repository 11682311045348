import { CentraSelection } from '@made-people/centra-models'
import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { CartWorkerEventTypes, isCartRequestContext } from '~/types/cart-worker-types'
import { createWorkerEvent, isWorkerEventAcknowledge, isWorkerEventData, isWorkerEventRequest, WorkerEvent, WorkerEventTopic } from '~/types/worker-event'

/**
 * This registers the centra API client and dispatches the initialize action
 * to set up market, cart and so on
 *
 * We should definitely explode if the config doesn't exist but the plugin is registered.
 *
 * @param app
 * @param inject
 */
export default (context: Context, inject: Inject) => {
  if (process.server) {
    throw new Error('The plugin "cart-worker-plugin" should not be initialized server-side')
  }

  inject('workerCartInit',
    (
      setter: (selection: unknown | undefined) => any,
      contextSetter: (market: string, language: string, country: string) => any,
      errorCallback: () => any
    ) => {
      if (!window.Worker) {
        errorCallback()
        return
      }

      const { app, store } = context

      const campaign = app.$cookies.get('campaign')
      if (campaign) {
        store.dispatch('frontend/setCurrentCampaignUri', campaign)
      }

      const backendApiUrl = app.$config.backendApiUrl
      const selectionGetRequestUrl = new URL(window.location.protocol + '//' + window.location.host + backendApiUrl + (!backendApiUrl.endsWith('/') ? '/' : '') + 'cart')

      selectionGetRequestUrl.searchParams.set('market', store.getters['frontend/currentMarketId'])
      selectionGetRequestUrl.searchParams.set('country', store.getters['frontend/currentCountryCode'])
      selectionGetRequestUrl.searchParams.set('language', store.getters['frontend/currentLanguageCode'])
      selectionGetRequestUrl.searchParams.set('campaign', store.getters['frontend/currentCampaign'])
      selectionGetRequestUrl.searchParams.set('pricelist', store.getters['frontend/currentPricelistId'])

      const ssrHeaders = store.getters['frontend/ssrHeaders'] as Record<string, string>
      const headers: Record<string, string> = {}
      if (ssrHeaders) {
        for (const [header, value] of Object.entries(ssrHeaders)) {
          if (header === 'host' || /^x-forwarded-/.test(header)) {
            headers[header] = value
          }
        }
      }

      if (!window.cartWorker) {
        window.cartWorker = new Worker('/workers/cart-worker-v2.js')
      }

      if (window.cartWorker instanceof Worker) {
        window.cartWorker.addEventListener('message', (event) => {
          const messagesToPost = handleIncomingMessage(event)
          if (Array.isArray(messagesToPost)) {
            for (const message of messagesToPost) {
              window.cartWorker!.postMessage(message)
            }
          }
        })
      } else {
        errorCallback()
      }

      function handleIncomingMessage (event: MessageEvent<any>): WorkerEvent<any, any, any>[] | void {
        const eventData = event.data
        if (isWorkerEventAcknowledge(eventData, CartWorkerEventTypes.Connected)) {
          return [
            createWorkerEvent(WorkerEventTopic.Acknowledge, CartWorkerEventTypes.Connected),
            createWorkerEvent(WorkerEventTopic.Request, CartWorkerEventTypes.CartResponse),
            createWorkerEvent(WorkerEventTopic.Request, CartWorkerEventTypes.CartRequestContext)
          ]
        } else if (isWorkerEventRequest(eventData, CartWorkerEventTypes.CartRequestParams)) {
          return [
            createWorkerEvent(WorkerEventTopic.Data, CartWorkerEventTypes.CartRequestParams, { requestUrl: selectionGetRequestUrl.toString(), headers })
          ]
        } else if (isWorkerEventData<CentraSelection, CartWorkerEventTypes.CartResponse>(eventData, CartWorkerEventTypes.CartResponse)) {
          setter(eventData.data)
          return [
            createWorkerEvent(WorkerEventTopic.Acknowledge, CartWorkerEventTypes.CartResponse)
          ]
        } else if (isWorkerEventData(eventData, CartWorkerEventTypes.CartRequestContext, isCartRequestContext)) {
          contextSetter(eventData.data.market, eventData.data.language, eventData.data.country)
          return [
            createWorkerEvent(WorkerEventTopic.Acknowledge, CartWorkerEventTypes.CartRequestContext)
          ]
        }
      }
    })
}
