import { mapGetters, mapState } from 'vuex'

export default {
  data () {
    return {
      gaId: null,
      cartEmail: null,
      accountId: null,
      accountEmail: null,
    }
  },
  computed: {
    ...mapGetters({
      cart: 'centra-cart/cart',
    }),
    ...mapState({
      account: state => state.account.account
    }),
  },
  watch: {
    cart: {
      deep: true,
      handler: function (cart) {
        if (cart?.address?.email) {
          this.cartEmail = cart.address.email
          this.setSentryUser()
        }
      }
    },
    account: {
      deep: true,
      handler: function (account) {
        if (account) {
          if (account.email) {
            this.accountEmail = account.email
          }
          if (account.customer) {
            this.accountId = account.customer
          }
          this.setSentryUser()
        }
      }
    },
  },
  mounted () {
    this.setSentryUser()
  },
  methods: {
    setSentryUser () {
      if (!this.$sentry) {
        return
      }
      if (!this.gaId) {
        this.setGaId()
      }
      const sentryUser = {
        id: this.accountId || this.gaId,
        email: this.accountEmail || this.cartEmail,
      }
      if (!sentryUser.id && !sentryUser.email) {
        return
      }
      console.debug('Setting Sentry user', sentryUser)
      this.$sentry.setUser(sentryUser)
    },
    setGaId () {
      const ga = window.ga
      if (!ga) {
        return
      }
      let trackingId = null
      ga((defaultTracker) => {
        let tracker = defaultTracker
        if (tracker === undefined) {
          tracker = ga.getAll()?.find(it => it?.get('trackingId') === this.$config.googleAnalyticsId)
        }
        if (tracker === undefined) {
          // This is required because for some reason no default tracker is set in MM stage.
          tracker = ga.getAll()?.[0]
        }
        if (tracker === undefined) {
          console.debug('No GA tracker found')
          return
        }
        const gaClientId = tracker.get('clientId')
        if (gaClientId) {
          trackingId = gaClientId
        }
      })
      if (trackingId) {
        this.gaId = trackingId
      }
    },
  },
}
