

export default {
  name: 'WashingsWarranty',
  props: {
    content: {
      type: Object,
      required: true,
    }
  },
  methods: {
    convertToProxy (image) {
      return this.$imgproxy.transform(image, 'rs:fit:1000/g:nowe/q:70')
    }
  },
}
